import { toJS, observable } from 'mobx';
import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';

class NewBookkeepingContractModalVm {
  constructor() {
    this.data = observable({
      bookkeepingServiceFee: toJS(BusinessHomeModel.business.customer.bookkeepingService.bookkeepingServiceFee),
      alimtalkToList: toJS(
        BusinessHomeModel.business.customer.alimtalkToList.DELEGATION_REGISTRATION_BOOKKEEPING_CONTRACT_REQUEST,
      ),
    });
  }
  bookkeepingServiceFee(e) {
    this.data.bookkeepingServiceFee = onlyNumber(removeCommas(e.target.value));
  }
  changeAlimtalkToList(e, index) {
    this.data.alimtalkToList[index].checkedYn = e.target.checked;
  }
  get saveActive() {
    let active = true;
    if (!this.data.bookkeepingServiceFee) {
      active = false;
    }
    const checkedYn = this.data.alimtalkToList.some((person) => {
      //알림톡 받으실분
      return person.checkedYn === true;
    });
    if (checkedYn === false) {
      active = false;
    }
    return active;
  }
  send(businessId) {
    const param = {
      bookkeepingServiceFee: this.data.bookkeepingServiceFee,
      alimTalkTargets: this.data.alimtalkToList
        .filter((person) => {
          return person.checkedYn === true;
        })
        .map((checkedPerson) => {
          return checkedPerson.id;
        }),
    };
    BusinessHomeService.alimtalkBookkeepingContract(businessId, param);
  }
}
export default NewBookkeepingContractModalVm;
