import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from '../BusinessRegistration.module.scss';

function CancelReason(props) {
  return useObserver(() => (
    <div className={st.confirmReason}>
      <div className={st.title}>
        취소사유
        <br />
        입력
      </div>
      <div className={st.content}>
        <textarea value={props.cancelReason} onChange={props.onChange}></textarea>
      </div>
    </div>
  ));
}
export default CancelReason;
