import { post, get } from '@common/module/httpRequest';
import { _alert, _confirm } from '@model/dialogModel';
import { pageRouter } from '@model/pageModel';

class ChangeEmailService {
  async changeEmail(param, token) {
    await post('/my-account/change-email/authorization', param, {
      'Change-Email-Authorization': `Bearer ${token}`,
    });
    await _alert('이메일이 변경 되었습니다.');
    pageRouter.replace(`/auth/login`);
  }
}
export default new ChangeEmailService();
