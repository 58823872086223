import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import userModel from '@model/userModel';
import socketService from '@service/socketService';

class presetService {
  async load() {
    const res = await get('/preset');
    //유저세팅
    userModel.setUser(res.data.user);
    //웹소켓관련 처리
    socketService.load(res.data.channelList);
  }
}
export default new presetService();
