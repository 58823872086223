import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import vm from './DragFileUploadVm';
import FileBtn from '@standby/common-ui/components/atoms/Button/FileBtn/FileBtn';
import st from './DragFileUpload.module.scss';
import clsx from 'clsx';

function DragFileUpload(props) {
  const fileInputRef = useRef();

  return useObserver(() => (
    <div className={st.fileUpload}>
      {vm.isUploadFile(props.uploadFile, props.multiple) === false && (
        <>
          <div
            className={st.drop}
            onDrop={(e) =>
              vm.fileDrop(
                e,
                props.uploadFile,
                props.changeUploadFile,
                props.multiple,
                props.allowFileType,
                props.fileSzie,
              )
            }
            onDragOver={vm.dragOver}
            onDragEnter={vm.dragEnter}
          >
            <div className={st.info}>
              아래 버튼을 클릭하거나
              <br />
              파일을 마우스로 끌어 첨부하세요.
            </div>
            <FileBtn btnType="important" btnState="upload" onClick={() => fileInputRef.current.click()}>
              {props.uploadBtnText}
            </FileBtn>
          </div>
          {props.uploadExplain && <div className={st.uploadExplain}>{props.uploadExplain}</div>}
        </>
      )}
      {vm.isUploadFile(props.uploadFile, props.multiple) === true && !props.multiple && (
        <div className={clsx(st.uploaded, vm.isFileDownload(props.uploadFile, props.multiple) ? st.download : null)}>
          <span
            className={st.limitText}
            onClick={
              vm.isFileDownload(props.uploadFile, props.multiple)
                ? () => vm.fileDownload(props.uploadFile?.downloadUrl)
                : null
            }
          >
            {props.uploadFile.name}
          </span>
          <button
            type="button"
            className={st.deleteBtn}
            onClick={() => vm.deleteFile(props.changeUploadFile, props.multiple)}
          />
        </div>
      )}
      {vm.isUploadFile(props.uploadFile, props.multiple) === true && props.multiple && (
        <div
          className={clsx(
            st.multipleUploaded,
            vm.isFileDownload(props.uploadFile, props.multiple) ? st.download : null,
          )}
        >
          <div className={st.title}>
            업로드 파일{' '}
            {vm.isFileDownload(props.uploadFile, props.multiple) ? (
              <button className={st.downloadBtn} onClick={() => vm.fileDownload(props.uploadFile?.downloadUrl)}>
                다운로드
              </button>
            ) : null}
          </div>
          <button
            type="button"
            className={st.deleteBtn}
            onClick={() => vm.deleteFile(props.changeUploadFile, props.multiple)}
          />
          <ul>
            {props.uploadFile.files.map((file, index) => (
              <li key={index}>{file?.name ? file?.name : file?.fileName}</li>
            ))}
          </ul>
        </div>
      )}
      <input
        type="file"
        accept={props.accept}
        multiple={props.multiple}
        onChange={(e) =>
          vm.filesSelected(
            e,
            props.uploadFile,
            props.changeUploadFile,
            props.multiple,
            props.allowFileType,
            props.fileSzie,
          )
        }
        ref={fileInputRef}
      />
    </div>
  ));
}
export default DragFileUpload;
