import { observable } from 'mobx';

const AlimtalkSendModalModel = observable({
  alimtalkSendData: null,
  setAlimtalkSendData(data) {
    this.alimtalkSendData = {
      businessName: data?.businessName || '',
      targets: data?.targets?.map((target) => {
        return {
          name: target?.name || '',
          id: target?.id || '',
        };
      }),
      selectedIds: [],
      content: '',
    };
  },
});

export default AlimtalkSendModalModel;
