import ValueAddedTaxModel from '@model/ValueAddedTaxModel';
import ValueAddedTaxService from '@service/ValueAddedTaxService';
import { runInAction, observable, toJS } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';

class ProcessVm {
  dataLoad(businessId, period, year) {
    ValueAddedTaxService.load(businessId, period, year);
  }
  get vatDetailDto() {
    return ValueAddedTaxModel.process.vatDetailDto;
  }
  get process() {
    return ValueAddedTaxModel.process;
  }
  async changeStatus(businessId, status, statusText) {
    if (await _confirm(`[${statusText}] 상태로 변경하시겠어요?`)) {
      await ValueAddedTaxService.changeStatus(status, businessId, ValueAddedTaxModel.process.vatId);
      ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgressStatus = status;
    }
  }
  openAlimtalkModal(businessId) {
    runInAction(() => {
      portalModel.title = '수동 알림톡 발송';
      portalModel.content = <AlimtalkSendModal businessId={businessId} />;
    });
  }
}
export default new ProcessVm();
