class BusinessRegistrationProcessingVm {
  openWindow(businessId) {
    window.open(
      window.location.origin + `/delegationRegistration/process?businessId=${businessId}`,
      `DelegationRegistrationProcessing${businessId}`,
      `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`,
    );
  }
}
export default new BusinessRegistrationProcessingVm();
