class partner {
  constructor() {
    this.바른 = 'barun';
    this.진평 = 'jinpyung';
  }

  get type() {
    const hostname = window.location.hostname;

    if (hostname.includes('barun')) return this.바른;
    if (hostname.includes('jinpyung')) return this.진평;
    return '';
  }
}

const Partner = new partner();

export default Partner;
