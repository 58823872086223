import { toJS, observable } from 'mobx';
import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';

class StockholderRegisterRequestModalVm {
  constructor() {
    this.data = observable({
      shareholder: toJS(BusinessHomeModel.business.customer.shareholder),
    });
  }
  get businessName() {
    return BusinessHomeModel.business.customer.businessName;
  }
  get saveActive() {
    let active = true;
    if (!this.data.shareholder.selectedYears) {
      active = false;
    }
    return active;
  }
  save(businessId) {
    const param = {
      year: this.data.shareholder.selectedYears,
      settlementMonth: this.data.shareholder.settlementMonth,
    };
    BusinessHomeService.alimtalkStockholderList(businessId, param);
  }
  years(e) {
    this.data.shareholder.selectedYears = e.target.value;
  }
}
export default StockholderRegisterRequestModalVm;
