import { useObserver } from 'mobx-react';
import { Route, withRouter, Link } from 'react-router-dom';
import { useEffect } from 'react';
import st from './MarketingTermsModal.module.scss';
import clsx from 'clsx';

function MarketingTermsModal(props) {
  return useObserver(() => <div className={clsx(st.termsContent, 'terms')}>{props.content}</div>);
}
export default MarketingTermsModal;
