class BusinessRegistrationProcessingVm {
  openWindow(businessId) {
    window.open(
      window.location.origin + `/BusinessRegistration/BusinessRegistration?businessId=${businessId}`,
      `BusinessRegistration${businessId}`,
      `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`,
    );
  }
}
export default new BusinessRegistrationProcessingVm();
