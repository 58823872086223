import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect, useRef, Fragment } from 'react';
import vm from './TimelineVm';
import st from './Timeline.module.scss';
import clsx from 'clsx';
import queryString from 'query-string';
import portalModel from '@model/portalModel';

function Timeline() {
  let location = useLocation();
  const businessId = queryString.parse(location.search).businessId;
  useEffect(() => {
    vm.getTimeline(businessId);
  }, []);
  const textarea = useRef();
  return useObserver(() => (
    <div className={st.timeline}>
      <form className={st.submitText} onSubmit={(e) => vm.submit(e, businessId, textarea)}>
        <textarea
          type="text"
          className={st.inputText}
          rows={1}
          placeholder="메모입력"
          name="message"
          value={vm.state.message}
          ref={textarea}
          onChange={(e) => vm.message(e, textarea)}
        />
        <button type="submit" className={st.submit} />
      </form>
      <div className={st.content}>
        <ul className={st.tab}>
          <li className={vm.state.activeTab === null ? st.active : null}>
            <button type="button" onClick={() => vm.changeTab(businessId, null)}>
              전체
            </button>
          </li>
          <li className={vm.state.activeTab === 'MEMO' ? st.active : null}>
            <button type="button" onClick={() => vm.changeTab(businessId, 'MEMO')}>
              메모
            </button>
          </li>
          <li className={vm.state.activeTab === 'PROGRESS' ? st.active : null}>
            <button type="button" onClick={() => vm.changeTab(businessId, 'PROGRESS')}>
              진행
            </button>
          </li>
          <li className={vm.state.activeTab === 'ALIMTALK' ? st.active : null}>
            <button type="button" onClick={() => vm.changeTab(businessId, 'ALIMTALK')}>
              알림톡
            </button>
          </li>
        </ul>
        <div className={st.list}>
          {vm.pinnedTimeLines.length !== 0 && (
            <>
              <div className={st.pinListTitle}>
                <input
                  type="checkbox"
                  checked={vm.state.pinned}
                  onChange={(e) => (vm.state.pinned = !vm.state.pinned)}
                  id="pinned"
                  className={st.radioCheckInput}
                />
                <label htmlFor="pinned" className={st.radioCheckLabel}></label>
                <span className={st.pinLengthText}>{vm.pinnedTimeLines.length} 고정됨</span>
              </div>
              {vm.state.pinned === true && (
                <ul className={st.pinList}>
                  {vm.pinnedTimeLines.map((pinnedTimeLine, index) => (
                    <li key={index}>
                      <span className={st.date}>{pinnedTimeLine.createdAt}</span>
                      {pinnedTimeLine.automaticYn !== true && (
                        <span className={st.name}>
                          {pinnedTimeLine.creatorType === 'CUSTOMER'
                            ? `고객(${pinnedTimeLine.creator})`
                            : pinnedTimeLine.creator}
                        </span>
                      )}
                      <span
                        className={clsx(
                          st.memo,
                          pinnedTimeLine.message.includes('!!!') ? st.important : null,
                          pinnedTimeLine.timelineType === 'PROGRESS' && pinnedTimeLine.creatorType === 'CUSTOMER'
                            ? st.auto_customer
                            : null,
                          pinnedTimeLine.timelineType === 'PROGRESS' && pinnedTimeLine.creatorType === 'ACCOUNTING_FIRM'
                            ? st.auto_accounting
                            : null,
                          pinnedTimeLine.timelineType === 'ALIMTALK' ? st.sendTalk : null,
                        )}
                      >
                        {pinnedTimeLine.message}
                        {pinnedTimeLine.timelineType === 'ALIMTALK' && (
                          <button
                            type="button"
                            className={st.viewTalkContent}
                            onClick={() =>
                              vm.viewTalkContent(
                                <div className={st.talkContentModal}>
                                  {pinnedTimeLine.alimtalkContent.split('\\n').map((line, brIndex) => (
                                    <span key={brIndex}>
                                      {line}
                                      <br />
                                    </span>
                                  ))}
                                </div>,
                              )
                            }
                          >
                            내용보기
                          </button>
                        )}
                      </span>
                      <div className={st.btnGroup}>
                        <button
                          type="button"
                          className={st.pin}
                          onClick={() => vm.pin(businessId, pinnedTimeLine.id, false)}
                        />
                        <button
                          type="button"
                          className={st.delete}
                          onClick={() => vm.delete(businessId, pinnedTimeLine.id)}
                        />
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </>
          )}
          {vm.normalTimeLines.length === 0 && <div className={st.empty}>새로운 메모가 없습니다.</div>}
          <ul className={st.nomalList}>
            {/* (normalTimeLine.timelineType === "PROGRESS" && normalTimeLine.creatorType === "CUSTOMER") ? st.auto_customer : null,
                    (normalTimeLine.timelineType === "PROGRESS" && normalTimeLine.creatorType === "ACCOUNTING_FIRM") ? st.auto_accounting : null, */}
            {vm.normalTimeLines.map((normalTimeLine, index) => (
              <Fragment key={index}>
                {normalTimeLine.timelineType === 'PROGRESS' ? (
                  <li className={st.progressLine}>
                    <span className={st.progressContent}>
                      <span className={st.date}>{normalTimeLine.createdAt}</span>
                      <span className={st.name}>
                        {normalTimeLine.creatorType === 'CUSTOMER'
                          ? `고객(${normalTimeLine.creator})`
                          : normalTimeLine.creator}
                      </span>
                      <span
                        className={clsx(
                          st.memo,
                          normalTimeLine.timelineType === 'PROGRESS' && normalTimeLine.creatorType === 'CUSTOMER'
                            ? st.auto_customer
                            : null,
                          normalTimeLine.timelineType === 'PROGRESS' && normalTimeLine.creatorType === 'ACCOUNTING_FIRM'
                            ? st.auto_accounting
                            : null,
                        )}
                      >
                        {normalTimeLine.message}
                        {normalTimeLine.timelineType === 'ALIMTALK' && (
                          <button
                            type="button"
                            className={st.viewTalkContent}
                            onClick={() =>
                              vm.viewTalkContent(
                                <div className={st.talkContentModal}>
                                  {normalTimeLine.alimtalkContent.split('\\n').map((line, brIndex) => (
                                    <span key={brIndex}>
                                      {line}
                                      <br />
                                    </span>
                                  ))}
                                </div>,
                              )
                            }
                          >
                            내용보기
                          </button>
                        )}
                      </span>
                    </span>
                  </li>
                ) : (
                  <li>
                    <span className={st.date}>{normalTimeLine.createdAt}</span>
                    {normalTimeLine.automaticYn !== true && (
                      <span className={st.name}>
                        {normalTimeLine.creatorType === 'CUSTOMER'
                          ? `고객(${normalTimeLine.creator})`
                          : normalTimeLine.creator}
                      </span>
                    )}
                    <span
                      className={clsx(
                        st.memo,
                        normalTimeLine.message.includes('!!!') ? st.important : null,
                        normalTimeLine.timelineType === 'ALIMTALK' ? st.sendTalk : null,
                      )}
                    >
                      {normalTimeLine.message}
                      {normalTimeLine.pinned === true && (
                        <button
                          type="button"
                          className={st.pin}
                          onClick={() => vm.pin(businessId, normalTimeLine.id, !normalTimeLine.pinned)}
                        />
                      )}
                      {normalTimeLine.timelineType === 'ALIMTALK' && (
                        <button
                          type="button"
                          className={st.viewTalkContent}
                          onClick={() =>
                            vm.viewTalkContent(
                              <div className={st.talkContentModal}>
                                {normalTimeLine.alimtalkContent.split('\\n').map((line, brIndex) => (
                                  <span key={brIndex}>
                                    {line}
                                    <br />
                                  </span>
                                ))}
                              </div>,
                            )
                          }
                        >
                          내용보기
                        </button>
                      )}
                    </span>
                    <div className={st.btnGroup}>
                      <button
                        type="button"
                        className={st.pin}
                        onClick={() => vm.pin(businessId, normalTimeLine.id, !normalTimeLine.pinned)}
                      />
                      <button
                        type="button"
                        className={st.delete}
                        onClick={() => vm.delete(businessId, normalTimeLine.id)}
                      />
                    </div>
                  </li>
                )}
              </Fragment>
            ))}
          </ul>
        </div>
      </div>
    </div>
  ));
}
export default Timeline;
