import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import BusinessRegistrationManagementModel from '@model/BusinessRegistrationManagementModel';

class BusinessRegistrationManagementService {
  async load() {
    const res = await get(`/businessRegistration/grid/column`);
    BusinessRegistrationManagementModel.setColumns(res.data.columns);
  }
  async gridData(params) {
    const res = await post('/businessRegistration/grid', params);
    BusinessRegistrationManagementModel.setTabs(res.data.tabs);
    return res.data;
  }
  async onDragStopped(params) {
    await put('/businessRegistration/grid/column/state', params);
  }
  async onChangeData(params) {
    await put('/businessRegistration/grid/column', params);
  }
}
export default new BusinessRegistrationManagementService();
