import { runInAction, observable, toJS } from 'mobx';
import { _alert, _confirm } from '@model/dialogModel';
import { downloadFileGet } from '@common/module/httpRequest';

class DragFileUploadVm {
  fileDrop(e, uploadFile, changeUploadFile, multiple, allowFileType, fileSzie) {
    e.preventDefault();
    const files = e.dataTransfer.files;
    this.fileUpload(files, uploadFile, changeUploadFile, multiple, allowFileType, fileSzie);
  }
  async fileUpload(files, uploadFile, changeUploadFile, multiple, allowFileType = '', fileSzie) {
    let validate = true;
    let _fileSize = fileSzie ? fileSzie : 5242880;
    for (let i = 0; i < files.length; i++) {
      if (validate === true) {
        if (files[i].size > _fileSize) {
          validate = false;
          await _alert('파일 용량이 초과되어 정상적으로 업로드 할 수 없습니다.');
        } else if (allowFileType && files[i].type.toLowerCase().includes(allowFileType.toLowerCase()) === false) {
          validate = false;
          await _alert(`${allowFileType}형식의 파일만 업로드 할 수 있습니다.`);
        }
      }
    }
    if (validate === true) {
      if (multiple) {
        changeUploadFile(Array.from(files), false, true);
      } else {
        changeUploadFile(files[0]);
      }
    }
  }
  dragOver(e) {
    e.preventDefault();
  }
  dragEnter(e) {
    e.preventDefault();
  }
  filesSelected(e, uploadFile, changeUploadFile, multiple, allowFileType, fileSzie) {
    this.fileUpload(e.target.files, uploadFile, changeUploadFile, multiple, allowFileType, fileSzie);
  }
  deleteFile(changeUploadFile, multiple) {
    if (multiple) {
      changeUploadFile([], true, true);
    } else {
      changeUploadFile(null);
    }
  }
  isUploadFile(uploadFile, multiple) {
    if (multiple) {
      if (uploadFile.files.length === 0) {
        return false;
      } else {
        return true;
      }
    } else {
      return uploadFile ? true : false;
    }
  }
  isFileDownload(uploadFile, multiple) {
    if (multiple) {
      if (uploadFile.files.length !== 0 && !uploadFile?.editYn) {
        return true;
      } else {
        return false;
      }
    } else {
      if (uploadFile && uploadFile.id) {
        return true;
      } else {
        return false;
      }
    }
  }
  async fileDownload(downloadUrl) {
    await downloadFileGet(downloadUrl);
  }
}
export default new DragFileUploadVm();
