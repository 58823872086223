import { toJS, observable } from 'mobx';
import BusinessHomeModel from '@model/BusinessHomeModel';
import BusinessHomeService from '@service/BusinessHomeService';

class NewCmsAccountContractModalVm {
  constructor() {
    this.data = observable({
      alimtalkToList: toJS(BusinessHomeModel.business.customer.alimtalkToList.CMS_ACCOUNT_CONTRACT_REQUEST),
    });
  }
  changeAlimtalkToList(e, index) {
    this.data.alimtalkToList[index].checkedYn = e.target.checked;
  }
  get saveActive() {
    let active = true;
    const checkedYn = this.data.alimtalkToList.some((person) => {
      //알림톡 받으실분
      return person.checkedYn === true;
    });
    if (checkedYn === false) {
      active = false;
    }
    return active;
  }
  send(businessId) {
    const param = {
      alimTalkTargets: this.data.alimtalkToList
        .filter((person) => {
          return person.checkedYn === true;
        })
        .map((checkedPerson) => {
          return checkedPerson.id;
        }),
    };
    BusinessHomeService.alimtalkCmsAccountContract(businessId, param);
  }
}
export default NewCmsAccountContractModalVm;
