import { observable, toJS } from 'mobx';

const AlimtalkCenterModel = observable({
  AlimtalkCenterList: [],
  setAlimtalkCenter(data) {
    if (data && data.length !== 0) {
      this.AlimtalkCenterList = data.map((item) => {
        return {
          businessName: item?.businessName || '',
          businessId: item?.businessId || '',
          customerType: item?.customerType || '',
          alimtalkReservation: {
            PAYROLL: {
              daysAgo: item?.alimtalkReservation?.PAYROLL?.daysAgo || '',
              targets: item?.alimtalkReservation?.PAYROLL?.targets.map((target) => {
                return {
                  id: target?.id || '',
                  name: target?.name || '',
                  checkedYn: target?.checkedYn ?? false,
                };
              }),
            },
            PREPARATION_FOR_CORPORATE_TAX_FILING: {
              daysAgo: item?.alimtalkReservation?.PREPARATION_FOR_CORPORATE_TAX_FILING?.daysAgo || '',
              targets: item?.alimtalkReservation?.PREPARATION_FOR_CORPORATE_TAX_FILING?.targets.map((target) => {
                return {
                  id: target?.id || '',
                  name: target?.name || '',
                  checkedYn: target?.checkedYn ?? false,
                };
              }),
            },
            PREPARATION_FOR_VALUE_ADDED_TAX_FILING: {
              daysAgo: item?.alimtalkReservation?.PREPARATION_FOR_VALUE_ADDED_TAX_FILING?.daysAgo || '',
              targets: item?.alimtalkReservation?.PREPARATION_FOR_VALUE_ADDED_TAX_FILING?.targets.map((target) => {
                return {
                  id: target?.id || '',
                  name: target?.name || '',
                  checkedYn: target?.checkedYn ?? false,
                };
              }),
            },
          },
        };
      });
    } else {
      this.AlimtalkCenterList = [];
    }
  },
});

export default AlimtalkCenterModel;
