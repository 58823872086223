import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import UserSettingVm from './UserSettingVm';
import st from './UserSetting.module.scss';
import clsx from 'clsx';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import FixBtn from '@standby/common-ui/components/atoms/Button/FixBtn/FixBtn';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import Check from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import regExp from '@common/module/regExp';

let vm;

function UserSetting() {
  useComponentWillMount(() => {
    vm = new UserSettingVm();
  });

  useEffect(() => {
    vm.load();
  }, []);

  return useObserver(() => (
    <>
      <div className={st.userSettingBox}>
        <div className={st.tabBox}>
          <ul className={st.tabList}>
            {vm.tabList.map((tab, index) => (
              <li
                onClick={() => vm.changeTab(tab)}
                key={index}
                className={clsx(st.tab, tab === vm.state.activeTab ? st.active : null)}
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className={st.logoutBtn}>
            <button type="button" onClick={vm.logout}>
              로그아웃
            </button>
          </div>
        </div>
        <div className={st.activeBox}>
          {vm.state.activeTab === '이메일' && (
            <>
              <div className={st.activeTitle}>
                이메일
                <div className={st.explain}>이메일은 아이디로 사용돼요. 변경시 유의해주세요.</div>
                <div className={st.explain}>아래의 이메일로 이메일 변경 링크를 보내드려요.</div>
              </div>
              <InputText name="name" value={vm.userSetData.email} disabled style={{ width: '314px' }} />
              <FixBtn className={st.changeBtn} onClick={() => vm.changeEmail(vm.userSetData.email)}>
                변경하기
              </FixBtn>
            </>
          )}
          {vm.state.activeTab === '비밀번호' && (
            <>
              <div className={st.activeTitle}>비밀번호</div>
              <form onSubmit={(e) => vm.passwordChangeSubmit(e)}>
                <div className={st.passwoard}>
                  <InputText
                    name="password"
                    type="password"
                    placeholder="현재 비밀번호"
                    style={{ width: '314px' }}
                    value={vm.state.password}
                    onChange={(e) => vm.setPassword(e)}
                    errorText={
                      <Error
                        name="password"
                        value={vm.state.password}
                        errorCase={{
                          required: '현재 비밀번호를 입력해 주세요.',
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                                vm.state.password,
                              ),
                            message: '올바른 비밀번호 형식으로 입력해 주세요.',
                          },
                        }}
                      />
                    }
                  />
                </div>
                <div className={st.passwoard}>
                  <InputText
                    name="newPassword"
                    type="password"
                    placeholder="새 비밀번호"
                    style={{ width: '314px' }}
                    value={vm.state.newPassword}
                    onChange={(e) => vm.setNewPassword(e)}
                    errorText={
                      <Error
                        name="newPassword"
                        value={vm.state.newPassword}
                        errorCase={{
                          required: '새 비밀번호를 입력해 주세요.',
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                                vm.state.newPassword,
                              ),
                            message: '올바른 비밀번호 형식으로 입력해 주세요.',
                          },
                        }}
                      />
                    }
                  />
                </div>
                <div className={st.passwoard}>
                  <InputText
                    name="newPasswordCheck"
                    type="password"
                    placeholder="새 비밀번호 확인"
                    style={{ width: '314px' }}
                    value={vm.state.newPasswordCheck}
                    onChange={(e) => vm.setNewPasswordCheck(e)}
                    errorText={
                      <Error
                        name="newPasswordCheck"
                        value={vm.state.newPasswordCheck}
                        errorCase={{
                          required: '비밀번호 확인을 입력해 주세요.',
                          validate: {
                            func: vm.isSame(),
                            message: '동일한 비밀번호를 입력해주세요.',
                          },
                        }}
                      />
                    }
                  />
                  <FixBtn className={st.changeBtn} type="submit">
                    변경하기
                  </FixBtn>
                </div>
              </form>
            </>
          )}
          {vm.state.activeTab === '회원정보' && (
            <>
              <form onSubmit={(e) => vm.nameChangeSubmit(e)}>
                <div className={st.activeTitle}>이름</div>
                <div>
                  <InputText
                    name="name"
                    placeholder="ex) 홍길동"
                    style={{ width: '314px' }}
                    value={vm.userSetData.name}
                    onChange={vm.changeName}
                    errorText={
                      <Error
                        name="name"
                        value={vm.userSetData.name}
                        errorCase={{
                          required: '이름을 입력하세요.',
                          pattern: {
                            value: regExp.onlyKorEng_nospacing().test(vm.userSetData.name),
                            message: '공백 없이 한글 또는 영문 이름을 입력해주세요.',
                          },
                        }}
                      />
                    }
                  />
                  <FixBtn className={st.changeBtn} type="submit">
                    변경하기
                  </FixBtn>
                </div>
              </form>
            </>
          )}
          {/* {vm.state.activeTab === "약관동의" && 
                        <>
                            <div className={st.activeTitle}>
                                마케팅 정보 수신동의
                                <div className={st.explain}>
                                    {`${vm.userSetData.marketingYnChangedDate.year}-${vm.userSetData.marketingYnChangedDate.month}-${vm.userSetData.marketingYnChangedDate.day} ${vm.userSetData.marketingAcceptYn ? '동의함' : '동의 철회함'}`}
                                </div>
                            </div>
                            <div className={st.termsAllCheckBox}>
                                <Check name="marketingAcceptYn" checked={vm.userSetData.marketingAcceptYn} onChange={vm.changeMarketingAcceptYn}>[선택] 할인정보 등 프로모션 정보 수신 동의</Check>
                                <button type='button' onClick={vm.marketingTermContentModal} className={st.textBtn}>내용보기</button>
                            </div>
                            <div className={st.termsCheckBox}>
                                <Check name="marketingAcceptEmailYn" checked={vm.userSetData.marketingAcceptEmailYn} onChange={vm.changeMarketingAcceptEmailYn}>이메일</Check>
                            </div>
                        </>
                    } */}
        </div>
      </div>
    </>
  ));
}
export default UserSetting;
