import CorporateTaxListModel from '@model/CorporateTaxListModel';
import { toJS } from 'mobx';

class CorporateTaxProcessingVm {
  openWindow(businessId, selectedIndex) {
    const period = CorporateTaxListModel.periodList[selectedIndex];
    window.open(
      window.location.origin + `/corporateTax/process?businessId=${businessId}&year=${period.value}`,
      `CorporateTax${businessId}`,
      `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`,
    );
  }
}
export default new CorporateTaxProcessingVm();
