import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './BookkeepingFileDownloadBtnRendererVm';
import st from './BookkeepingFileDownloadBtnRenderer.module.scss';
function BookkeepingFileDownloadBtnRenderer(props) {
  return useObserver(() =>
    props.data.bookkeepingContractFileUrl ? (
      <button
        type="button"
        className={st.filedownLoad}
        onClick={() => vm.fileDown(props.data.bookkeepingContractFileUrl)}
      >
        계약서 다운로드
      </button>
    ) : (
      '-'
    ),
  );
}
export default BookkeepingFileDownloadBtnRenderer;
