import { useObserver } from 'mobx-react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './JoinVm';
import st from './Join.module.scss';
import clsx from 'clsx';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import regExp from '@common/module/regExp';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import TextBtn from '@standby/common-ui/components/atoms/Button/TextBtn/TextBtn';
import partner from '@common/module/partner';

function Join() {
  useEffect(() => {
    setTimeout(() => {
      vm.state.action = true;
    }, 700);
    vm.loadTerms();
  }, []);
  return useObserver(() => (
    <>
      <div className={clsx(st.joinBackground, vm.state.action ? st.action : null)}>
        <div className={st.firecracker}>
          <img src="/images/firecracker/1.svg" alt="별1" />
          <img src="/images/firecracker/2.svg" alt="별2" />
          <img src="/images/firecracker/3.svg" alt="별3" />
          <img src="/images/firecracker/4.svg" alt="별4" />
          <img src="/images/firecracker/5.svg" alt="별5" />
          <img src="/images/firecracker/6.svg" alt="별6" />
          <img src="/images/firecracker/7.svg" alt="별7" />
          <img src="/images/firecracker/8.svg" alt="별8" />
        </div>
        <div className={st.square}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      <div className={st.join}>
        {partner.type === partner.바른 && (
          <img src="/images/joinTitle_barun.svg" alt="바른 로고" className={st.joinTitleImg} />
        )}
        {partner.type === partner.진평 && (
          <img src="/images/joinTitle_jp.svg" alt="진평 로고" className={st.joinTitleImg} />
        )}
        <form className={st.joinForm} onSubmit={(e) => vm.submit(e)}>
          <div className={st.information}>
            <div className={st.inputRow}>
              <label className={st.inputLabel} htmlFor="email">
                이메일
              </label>
              <div className={st.inputs}>
                <InputText
                  name="email"
                  fullSize
                  value={vm.state.email}
                  id="email"
                  onChange={(e) => vm.changeEmail(e)}
                  errorText={
                    <Error
                      name="email"
                      value={vm.state.email}
                      errorCase={{
                        required: '이메일 주소를 입력하세요.',
                        pattern: {
                          value: regExp.email().test(vm.state.email),
                          message: '올바른 이메일 형식으로 입력하세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.inputRow}>
              <label className={st.inputLabel} htmlFor="password">
                비밀번호
              </label>
              <div className={st.inputs}>
                <div>
                  <InputText
                    name="password"
                    fullSize
                    type="password"
                    id="password"
                    value={vm.state.password}
                    onChange={(e) => vm.changePassword(e)}
                    errorText={
                      <Error
                        name="password"
                        value={vm.state.password}
                        errorCase={{
                          required: '비밀번호를 입력하세요.',
                          pattern: {
                            value:
                              /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                                vm.state.password,
                              ),
                            message: '올바른 비밀번호 형식으로 입력하세요',
                          },
                        }}
                      />
                    }
                  />
                </div>
                <ul className={st.passwordEx}>
                  <li>8글자 이상 입력</li>
                  <li>영문, 숫자, 특수문자 각 1개 이상 사용</li>
                </ul>
              </div>
            </div>
            <div className={st.inputRow}>
              <label className={st.inputLabel} htmlFor="passwordCheck">
                비밀번호 확인
              </label>
              <div className={st.inputs}>
                <InputText
                  name="passwordCheck"
                  fullSize
                  type="password"
                  id="passwordCheck"
                  value={vm.state.passwordCheck}
                  onChange={(e) => vm.changePasswordCheck(e)}
                  errorText={
                    <Error
                      name="passwordCheck"
                      value={vm.state.passwordCheck}
                      errorCase={{
                        required: '비밀번호 확인을 입력하세요',
                        validate: {
                          func: vm.state.password === vm.state.passwordCheck,
                          message: '동일한 비밀번호를 입력하세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
            <div className={st.inputRow}>
              <label className={st.inputLabel} htmlFor="name">
                성함
              </label>
              <div className={st.inputs}>
                <InputText
                  name="name"
                  fullSize
                  id="name"
                  value={vm.state.name}
                  onChange={(e) => vm.changeName(e)}
                  errorText={
                    <Error
                      name="name"
                      value={vm.state.name}
                      errorCase={{
                        required: '성함을 입력하세요.',
                        pattern: {
                          value: regExp.onlyKorEng_nospacing().test(vm.state.name),
                          message: '공백 없이 한글 또는 영문으로 입력하세요.',
                        },
                      }}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className={st.agree}>
            <CheckBox className={st.agreeAll} checked={vm.isAgreeAll} onChange={(e) => vm.agreeAll(e)}>
              모두 동의합니다.
            </CheckBox>
            <div className={st.agreeInside}>
              <div className={st.agreeAllEx}>스탠바이 헬프미 회원가입 동의가 포함되어 있어요.</div>
              {vm.state.terms.map((term, index) => (
                <div className={st.agreeRow} key={index}>
                  <CheckBox
                    checked={term.checked}
                    onChange={(e) => vm.agreeCheck(e, index)}
                    className={st.agreeCheckbox}
                  >
                    {term.requiredYn ? '[필수] ' : ''}
                    {term.title}
                  </CheckBox>
                  {term.content && (
                    <TextBtn
                      className={st.textBtn}
                      onClick={() =>
                        vm.openAgreeModal(
                          term.title,
                          <div
                            className={clsx(st.agreeContent, 'terms')}
                            dangerouslySetInnerHTML={{ __html: term.content }}
                          ></div>,
                        )
                      }
                    >
                      상세보기
                    </TextBtn>
                  )}
                </div>
              ))}
            </div>
            <div className={st.btnArea}>
              <SquareBtn className={st.joinBtn} disabled={!vm.isSavePossible} type="submit">
                동의하고 회원가입하기
              </SquareBtn>
            </div>
            <div className={st.loginLink}>
              <Link to="/auth/login">로그인</Link>
            </div>
          </div>
        </form>
      </div>
    </>
  ));
}
export default Join;
