import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './BusinessName.module.scss';
import vm from './BusinessNameVm';

function BusinessName(props) {
  return useObserver(() => (
    <span className={st.businessName}>
      {props.value}
      <button type="button" className={st.companyHome} onClick={() => vm.openHomeWindow(props.data.businessId)} />
      <button type="button" className={st.historyBtn} onClick={() => vm.openHistoryWindow(props.data.businessId)} />
    </span>
  ));
}
export default BusinessName;
