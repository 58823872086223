import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import NewBookkeepingContractModalVm from './NewBookkeepingContractModalVm';
import clsx from 'clsx';
import queryString from 'query-string';
import st from './NewBookkeepingContractModal.module.scss';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import { addCommas } from '@common/module/replaceNumber';
import MessageHint from '@commonComponents/MessageHint/MessageHint';

let vm;

function NewBookkeepingContractModal() {
  useComponentWillMount(() => {
    vm = new NewBookkeepingContractModalVm();
  });
  let location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  return useObserver(() => (
    <div className={st.modalContent}>
      <ul>
        <li>
          <div className={st.title}>
            월 기장료
            <br />
            (부가세 제외)
          </div>
          <div className={st.rowContent}>
            <InputText
              name=""
              value={addCommas(vm.data.bookkeepingServiceFee)}
              onChange={(e) => vm.bookkeepingServiceFee(e)}
              style={{ width: '298px', textAlign: 'right' }}
            />
            <span className={st.inputWon}>원</span>
          </div>
        </li>
        <li>
          <div className={st.title} style={{ top: '-11px' }}>
            알림톡 받으실 분
          </div>
          <div className={st.rowContent}>
            <ul className={st.checkList}>
              {vm.data.alimtalkToList.map((person, index) => (
                <li key={index}>
                  <CheckBox checked={person.checkedYn} onChange={(e) => vm.changeAlimtalkToList(e, index)}>
                    {person.name}님
                  </CheckBox>
                </li>
              ))}
            </ul>
          </div>
        </li>
      </ul>
      <div className={st.buttonArea}>
        <div>
          <button
            className={clsx(st.saveBtn, st.send)}
            disabled={!vm.saveActive}
            style={{ width: '100%' }}
            onClick={() => vm.send(businessId)}
          >
            <span>변경계약서 날인요청 알림톡 발송</span>
          </button>
        </div>
      </div>
      <MessageHint text="알림톡 메세지보기" style={{ marginTop: '8px' }}>
        <img src="/images/talkPreview_기장계약서_새로작성.png?1" />
      </MessageHint>
    </div>
  ));
}
export default NewBookkeepingContractModal;
