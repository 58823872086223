class BusinessNameTimelineVm {
  openWindow(businessId) {
    window.open(
      window.location.origin + `/timeline?businessId=${businessId}`,
      `timeline${businessId}`,
      `width=680, height=900, toolbar=0,location=0,menubar=0`,
    );
  }
}
export default new BusinessNameTimelineVm();
