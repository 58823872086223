import { get, post } from '@common/module/httpRequest';
import { objectToFormData } from '@common/module/submit';
import CorporateTaxModel from '@model/CorporateTaxModel';
import { _alert } from '@model/dialogModel';
import LoadingModel from '@model/LoadingModel';

class CorporateTaxService {
  async load(businessId, year) {
    const res = await get(`/business/${businessId}/corporateTax/${year}`);

    CorporateTaxModel.setProcess(res.data);
  }
  async sendTalk(param, businessId, type) {
    await post(`/business/${businessId}/corporateTax/${type}`, param);

    if (window?.opener?.location) {
      window.opener.location.reload();
    }

    await _alert(
      '알림톡 발송을 시작합니다.',
      '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
    );
    window.location.reload();
  }
  async taxInfoLoad(businessId, year, taxBillSendWayType, instalmentYn) {
    const res = await get(
      `/business/${businessId}/corporateTax/${year}/taxInfo?taxBillSendWayType=${taxBillSendWayType}&instalmentYn=${instalmentYn}`,
    );

    CorporateTaxModel.setTaxInfo(res.data.taxInfo);
  }
  async sendCompletedNotice(param, businessId) {
    try {
      LoadingModel.on();
      const formData = new FormData();

      objectToFormData(formData, param);

      await post(`/business/${businessId}/corporateTax/completedNotice`, formData);

      LoadingModel.off();

      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
  async saveCompletedNotice(param, businessId) {
    try {
      LoadingModel.on();

      const formData = new FormData();

      objectToFormData(formData, param);

      await post(`/business/${businessId}/corporateTax/complete`, formData);

      LoadingModel.off();

      await _alert('저장되었습니다.');
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
  async sendNationalTaxCompletedNotice(param, businessId) {
    try {
      LoadingModel.on();

      const formData = new FormData();

      objectToFormData(formData, param);
      await post(`/business/${businessId}/corporateTax/nationalTax/completedNotice`, formData);

      LoadingModel.off();

      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
  async saveaNationalTaxCompletedNotice(param, businessId) {
    try {
      LoadingModel.on();

      const formData = new FormData();

      objectToFormData(formData, param);
      await post(`/business/${businessId}/corporateTax/nationalTax/complete`, formData);

      LoadingModel.off();

      await _alert('저장되었습니다.');
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
  async sendLocalTaxCompletedNotice(param, businessId) {
    try {
      LoadingModel.on();

      const formData = new FormData();

      objectToFormData(formData, param);
      await post(`/business/${businessId}/corporateTax/localTax/completedNotice`, formData);

      LoadingModel.off();

      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
  async saveLocalTaxCompletedNotice(param, businessId) {
    try {
      LoadingModel.on();
      const formData = new FormData();

      objectToFormData(formData, param);
      await post(`/business/${businessId}/corporateTax/localTax/complete`, formData);

      LoadingModel.off();

      await _alert('저장되었습니다.');
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
}

export default new CorporateTaxService();
