import BusinessListService from '@service/BusinessListService';
import BusinessListModel from '@model/BusinessListModel';
import { get, post } from '@common/module/httpRequest';
import { runInAction, observable, toJS } from 'mobx';
import { pageRouter } from '@model/pageModel';

class BusinessListVm {
  load() {
    if (this.selectedUserId) {
      BusinessListModel.columns = [];
      BusinessListService.load();
    } else {
      this.loadGridFilter();
    }
  }
  get columns() {
    return BusinessListModel.columns;
  }
  get employeeList() {
    return BusinessListModel.employeeList;
  }
  get selectedUserId() {
    return BusinessListModel.selectedUserId;
  }
  resetLoad() {
    BusinessListModel.columns = [];
    BusinessListModel.selectedUserId = null;
  }
  loadGridFilter() {
    BusinessListService.loadGridFilter();
  }
  changeSelectedUserId(e) {
    BusinessListModel.selectedUserId = e.target.value;
  }
  gridData(server) {
    return {
      getRows: async (params) => {
        let jsonRequest = params.request;
        const selectedUserId = this.selectedUserId;
        const res = await BusinessListService.gridData(jsonRequest, selectedUserId);
        params.successCallback(res.data, res.lastRow);
      },
    };
  }
  onDragStopped(e) {
    const sendData = e.columnApi.getColumnState();
    BusinessListService.onDragStopped(sendData);
  }
  onChangeData(e) {
    const sendData = {
      type: e.type,
      pinned: e.pinned,
      visible: e.visible,
      colIds: e?.columns?.map((column) => column.colId),
      value: e.value,
      colId: e?.column?.colId,
      rowData: e.data,
    };
    const selectedUserId = this.selectedUserId;
    BusinessListService.onChangeData(sendData, selectedUserId);
  }
  onGridPageSizeChanged(e, gridRef) {
    const value = e.target.value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }
}
export default new BusinessListVm();
