import { get, post, put } from '@common/module/httpRequest';
import { objectToFormData } from '@common/module/submit';
import DelegationRegistrationProcessModel from '@model/DelegationRegistrationProcessModel';
import { _alert } from '@model/dialogModel';
import LoadingModel from '@model/LoadingModel';

class DelegationRegistrationProcessService {
  async load(businessId) {
    const res = await get(`/business/${businessId}/delegationRegistration`);

    DelegationRegistrationProcessModel.setProcess(res.data);
  }
  async loadMetropolitansList() {
    const res = await get(`/metropolitans`);

    return res.data.selectableMetropolitanTypes;
  }
  async findBusiness(param) {
    const res = await get(`/business/delegationRegistration/findBusiness`, param);

    return res.data.businesses;
  }
  async cancleProcess(businessId, _cancelReason) {
    await put(`/business/${businessId}/delegationRegistration/cancel`, {
      cancelReason: _cancelReason,
    });

    if (window?.opener?.location) {
      window.opener.location.reload();
    }

    await _alert('이용등록이 취소되었습니다.');
    window.close();
  }

  async sendDelegationRegistration(param, businessId) {
    try {
      LoadingModel.on();

      const formData = new FormData();

      objectToFormData(formData, param);

      await post(`/business/${businessId}/delegationRegistration/request`, formData);

      LoadingModel.off();

      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      await _alert(
        '이용등록 알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
  async saveDelegationRegistration(param, businessId) {
    try {
      LoadingModel.on();

      const formData = new FormData();

      objectToFormData(formData, param);
      await post(`/business/${businessId}/delegationRegistration`, formData);

      LoadingModel.off();

      await _alert('저장되었습니다.');
    } catch {
      LoadingModel.off();
    }
  }
  async sendCmsAlimTalk(param, businessId) {
    try {
      LoadingModel.on();

      await post(`/business/${businessId}/delegationRegistration/cmsAccountRegistration`, param);

      LoadingModel.off();

      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      await _alert(
        'CMS 알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
}

export default new DelegationRegistrationProcessService();
