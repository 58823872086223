import { runInAction, observable, toJS } from 'mobx';
import formDataToJson from '@common/module/submit';
import FindPasswordService from '@service/FindPasswordService';
import { _alert, _confirm } from '@model/dialogModel';
import regExp from '@common/module/regExp';

class FindPasswordVm {
  constructor() {
    this.state = observable({
      email: '',
      sendYn: true,
    });
  }
  changeEmail(e) {
    this.state.email = regExp.deleteSpace(e.target.value);
  }
  async submit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      await FindPasswordService.findPassword(param);
      if (this.state.sendYn === true) {
        await _alert('이메을 다시 발송했습니다.');
      } else {
        this.state.sendYn = true;
      }
    }
  }
}
export default new FindPasswordVm();
