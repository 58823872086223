import { removeCommas } from '@common/module/replaceNumber';
import formDataToJson from '@common/module/submit';

import ModalEditService from './service/ModalEditService';

class ModalEditVm {
  async submit(e, type, businessId, inputType, parent = 'home') {
    e.preventDefault();
    let value = formDataToJson(e.target).data.value;

    if (value === 'true' || value === 'false') {
      value = value === 'true';
    } else if (inputType === 'price') {
      value = removeCommas(value);
    }

    let param = {};

    if (parent === 'home') {
      param = {
        type,
        value,
      };
    } else {
      param = { [type]: value };
    }

    ModalEditService.edit(param, businessId, parent);
  }
}

export default new ModalEditVm();
