import { get, post } from '@common/module/httpRequest';
import { objectToFormData } from '@common/module/submit';
import { _alert } from '@model/dialogModel';
import LoadingModel from '@model/LoadingModel';
import ValueAddedTaxModel from '@model/ValueAddedTaxModel';

class ValueAddedTaxService {
  async load(businessId, period, year) {
    const res = await get(`/company/${businessId}/vat?year=${year}&period=${period}`);

    ValueAddedTaxModel.setProcess(res.data);
  }
  async sendTalk(param, businessId, vatId, type) {
    const _param = param;

    if (ValueAddedTaxModel.process.vatDetailDto.isFinalPage) {
      _param.scheduledReportProgressStatus = null;
    } else {
      _param.scheduledReportProgressStatus = ValueAddedTaxModel.process.vatDetailDto.scheduledReportProgressStatus;
    }

    await post(`/company/${businessId}/vat/${vatId}/alimTalk?type=${type}`, _param);

    if (window?.opener?.location) {
      window.opener.location.reload();
    }

    await _alert(
      '알림톡 발송을 시작합니다.',
      '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
    );
    window.location.reload();
  }
  async sendVat(param, businessId, vatId) {
    try {
      LoadingModel.on();

      const formData = new FormData();

      objectToFormData(formData, param);
      await post(`/company/${businessId}/vat/${vatId}/alimTalk/completed`, formData, { Converter: 'vat' });

      LoadingModel.off();

      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      await _alert(
        '알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      window.location.reload();
    } catch {
      LoadingModel.off();
    }
  }
  async saveVat(param, businessId, vatId) {
    try {
      LoadingModel.on();
      const formData = new FormData();

      objectToFormData(formData, param);
      await post(`/company/${businessId}/vat/${vatId}`, formData, { Converter: 'vat' });

      LoadingModel.off();

      await _alert('저장되었습니다.');
    } catch {
      LoadingModel.off();
    }
  }
  async sendPreliminaryNoticeAmount(param, businessId, vatId) {
    const res = await post(`/company/${businessId}/vat/${vatId}/alimTalk/preliminaryNoticeAmount`, param);

    if (window?.opener?.location) {
      window.opener.location.reload();
    }

    await _alert(
      '알림톡 발송을 시작합니다.',
      '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
    );
    window.location.reload();
  }
  async changeStatus(status, businessId, vatId) {
    const res = await post(`/company/${businessId}/vat/${vatId}/scheduledReportProgress?status=${status}`);

    // if(status === "예정신고X_예정고지O") {
    //     ValueAddedTaxModel.set예정신고X_예정고지O(res.data);
    // }
    // if(status === "예정신고O") {
    //     ValueAddedTaxModel.set예정신고O(res.data);
    // }
    if (window?.opener?.location) {
      window.opener.location.reload();
    }

    window.location.reload();
  }
}

export default new ValueAddedTaxService();
