import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import companySearchModalVm from './CompanySearchModalVm';
import st from './CompanySearchModal.module.scss';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import clsx from 'clsx';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import ScrollArea from 'react-custom-scrollbars-2';

let vm;
function CompanySearchModal() {
  useComponentWillMount(() => {
    vm = new companySearchModalVm();
  });
  useEffect(() => {
    vm.loadMetropolitansList();
  }, []);
  return useObserver(() => (
    <div className={st.wrap}>
      <form onSubmit={(e) => vm.findBusiness(e)}>
        <div className={st.row}>
          <div className={st.title}>회사 본점 소재지</div>
          <div className={st.content}>
            <Select
              name="sido"
              style={{ width: '220px' }}
              value={vm.state.metropolitanType}
              onChange={(e) => vm.metropolitanType(e)}
            >
              <option value="">시/도</option>
              {vm.state.selectableMetropolitanTypes.map((item, index) => (
                <option value={item.metropolitanType} key={index}>
                  {item.metropolitanType}
                </option>
              ))}
            </Select>
            {vm.state.metropolitanType &&
              vm.state.metropolitanType !== '서울특별시' &&
              vm.state.metropolitanType !== '세종특별자치시' && (
                <Select
                  name="sigungu"
                  style={{ width: '220px', marginLeft: '8px' }}
                  value={vm.state.sigungu}
                  onChange={(e) => vm.sigungu(e)}
                >
                  <option value="">시/군/구</option>
                  {vm.selectableSigungusList(vm.state.metropolitanType).map((item, index) => (
                    <option value={item} key={index}>
                      {item}
                    </option>
                  ))}
                </Select>
              )}
          </div>
        </div>
        <div className={st.row}>
          <div className={st.title}>한글 회사이름</div>
          <div className={clsx(st.content, st.exContent)}>
            <InputText
              name="name"
              style={{ width: '356px' }}
              value={vm.state.companyName}
              onChange={(e) => vm.companyName(e)}
            />
            <button type="submit" className={st.searchBtn}>
              조회
            </button>
            <span className={st.ex}>‘주식회사’를 제외한 나머지 법인명을 입력하세요.</span>
          </div>
        </div>
      </form>
      {vm.state.businesses !== null && (
        <div className={st.selectedList}>
          {vm.state.businesses.length === 0 && (
            <div className={st.empty}>
              정보를 찾을 수 없어요.
              <br />
              본점 소재지와 회사이름이 정확한지 확인하세요.
            </div>
          )}
          {vm.state.businesses.length !== 0 && (
            <ScrollArea
              className={st.scrollArea}
              renderThumbVertical={(props) => <div {...props} style={{ width: '3px', backgroundColor: '#ADAFB1' }} />}
            >
              <ul className={st.businessList}>
                {vm.state.businesses.map((business, index) => (
                  <li key={index}>
                    <Radio
                      name="businesses"
                      value={business.businessId}
                      checked={business.businessId === vm.state.selectedBusinessId}
                      onChange={(e) => vm.selectedBusinessId(e)}
                    >
                      <span className={st.radioTitle}>{business.companyName}</span>
                      <br />
                      <span className={st.radioSub}>{business.address}</span>
                    </Radio>
                  </li>
                ))}
              </ul>
            </ScrollArea>
          )}
        </div>
      )}
      {vm.state.businesses !== null && vm.state.businesses.length !== 0 && (
        <div className={st.btnWrap}>
          <SquareBtn style={{ width: '300px' }} onClick={() => vm.selectBusiness()}>
            다음
          </SquareBtn>
        </div>
      )}
    </div>
  ));
}
export default CompanySearchModal;
