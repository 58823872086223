import ValueAddedTaxListModel from '@model/ValueAddedTaxListModel';

class ValueAddedTaxProcessingVm {
  openWindow(businessId, selectedIndex) {
    const period = ValueAddedTaxListModel.periodList[selectedIndex];
    window.open(
      window.location.origin +
        `/valueAddedTax/process?businessId=${businessId}&year=${period.year}&period=${period.value}`,
      `valueAddedTax${businessId}`,
      `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`,
    );
  }
}
export default new ValueAddedTaxProcessingVm();
