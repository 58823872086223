import { observable } from 'mobx';

const userModel = observable({
  user: {
    name: null,
    id: null,
  },
  setUser(data) {
    this.user.name = data?.name || null;
    this.user.id = data?.id || null;
  },
  removeUser() {
    this.user.name = null;
  },
});

export default userModel;
