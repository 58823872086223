import { observable } from 'mobx';

const BusinessHomeModel = observable({
  business: null,
  setBusiness(data) {
    this.business = {
      customer: {
        shareholder: {
          lastDay: data?.customer?.shareholder?.lastDay || '',
          settlementMonth: data?.customer?.shareholder?.settlementMonth || '',
          shareholderListExcelFile: data?.customer?.shareholder?.shareholderListExcelFile || '',
          shareholderListPDFFile: data?.customer?.shareholder?.shareholderListPDFFile || '',
          years: data?.customer?.shareholder?.years ? data?.customer?.shareholder?.years : [],
          selectedYears: data?.customer?.shareholder?.years
            ? data?.customer?.shareholder?.years[data.customer.shareholder.years.length - 1]
            : '',
        },
        contractEndDate: data?.customer?.contractEndDate || '',
        businessName: data?.customer?.businessName || '',
        address: data?.customer?.address || '',
        businessRegistrationNumber: data?.customer?.businessRegistrationNumber || '',
        cssn: data?.customer?.cssn || '',
        representative: data?.customer?.representative
          ? data?.customer?.representative.map((item) => {
              return item || '';
            })
          : [],
        managers: data?.customer?.managers
          ? data?.customer?.managers.map((manager) => {
              return {
                id: manager?.id || '',
                name: manager?.name || '',
                position: manager?.position || '',
                phoneNumber: manager?.phoneNumber || '',
                email: manager?.email || '',
                duplicate: manager?.duplicate || false,
              };
            })
          : [],
        delegationRegistrationYn: data?.customer?.delegationRegistrationYn || false,
        businessType: data?.customer?.businessType || '',
        taxationYn: data?.customer?.taxationYn ?? '',
        faithfulnessTaxPayerYn: data?.customer?.faithfulnessTaxPayerYn ?? '',
        capital: data?.customer?.capital ?? '',
        establishedAt: data?.customer?.establishedAt ?? '',
        branch: data?.customer?.branch ?? false,
        businessRegistration: {
          issuedAt: data?.customer?.businessRegistration?.issuedAt ?? '',
          businessStartAt: data?.customer?.businessRegistration?.businessStartAt ?? '',
          unregisteredYn: data?.customer?.businessRegistration?.unregisteredYn ?? false,
          businessRegistrationNumber: data?.customer?.businessRegistration?.businessRegistrationNumber ?? '',
          businessRegistrationFile: {
            name: data?.customer?.businessRegistration?.businessRegistrationFile?.name || '',
            url: data?.customer?.businessRegistration?.businessRegistrationFile?.url || '',
          },
          businessTypeAndItemList:
            data?.customer?.businessRegistration?.businessTypeAndItemList &&
            data?.customer?.businessRegistration?.businessTypeAndItemList.length !== 0
              ? data?.customer?.businessRegistration?.businessTypeAndItemList.map((TypeAndItem) => {
                  return {
                    type: TypeAndItem.type || '',
                    item: TypeAndItem.item || '',
                  };
                })
              : [
                  {
                    type: '',
                    item: '',
                  },
                ],
        },
        companyCopyFile: data?.customer?.companyCopyFile || '',
        bookkeepingService: {
          staff: {
            name: data?.customer?.bookkeepingService?.staff?.name || '',
            value: data?.customer?.bookkeepingService?.staff?.value || '',
          },
          team: data?.customer?.bookkeepingService?.team || '',
          customerType: data?.customer?.bookkeepingService?.customerType || '',
          bookkeepingServiceFee: data?.customer?.bookkeepingService?.bookkeepingServiceFee ?? '',
          bookkeepingServiceStartAt: data?.customer?.bookkeepingService?.bookkeepingServiceStartAt ?? '',
          freeBookkeepingServiceEndAt: data?.customer?.bookkeepingService?.freeBookkeepingServiceEndAt ?? '',
          bookkeepingContractFile: data?.customer?.bookkeepingService?.bookkeepingContractFile ?? '',
          cmsAccount: {
            cmsAccountAcceptAt: data?.customer?.bookkeepingService?.cmsAccount?.cmsAccountAcceptAt || '',
            cmsAccountFile: data?.customer?.bookkeepingService?.cmsAccount?.cmsAccountFile || '',
            bankbook: {
              bankName: data?.customer?.bookkeepingService?.cmsAccount?.bankbook?.bankName || '신한은행',
              accountNumber: data?.customer?.bookkeepingService?.cmsAccount?.bankbook?.accountNumber || '111-111-11111',
              fileDownloadUrl: data?.customer?.bookkeepingService?.cmsAccount?.bankbook?.fileDownloadUrl || '',
            },
          },
        },
        accountingService: {
          fiscalYear: data?.customer?.accountingService?.fiscalYear ?? '',
          reportPeriod: data?.customer?.accountingService?.reportPeriod ?? '',
          halfTotalOutputTax: data?.customer?.accountingService?.halfTotalOutputTax ?? '',
          halfNationalTaxPayable: data?.customer?.accountingService?.halfNationalTaxPayable ?? '',
          beforePreliminaryReturnType: {
            year: data?.customer?.accountingService?.beforePreliminaryReturnType?.year || '',
            period: data?.customer?.accountingService?.beforePreliminaryReturnType?.period || '',
            name: data?.customer?.accountingService?.beforePreliminaryReturnType?.name || '',
            value: data?.customer?.accountingService?.beforePreliminaryReturnType?.value || '',
            selectList: data?.customer?.accountingService?.beforePreliminaryReturnType?.selectList
              ? data?.customer?.accountingService?.beforePreliminaryReturnType?.selectList.map((item) => {
                  return {
                    name: item?.name || '',
                    value: item?.value || '',
                  };
                })
              : [],
          },
          currentPreliminaryReturnType: {
            year: data?.customer?.accountingService?.currentPreliminaryReturnType?.year || '',
            period: data?.customer?.accountingService?.currentPreliminaryReturnType?.period || '',
            name: data?.customer?.accountingService?.currentPreliminaryReturnType?.name || '',
            value: data?.customer?.accountingService?.currentPreliminaryReturnType?.value || '',
            selectList: data?.customer?.accountingService?.currentPreliminaryReturnType?.selectList
              ? data?.customer?.accountingService?.currentPreliminaryReturnType?.selectList.map((item) => {
                  return {
                    name: item?.name || '',
                    value: item?.value || '',
                  };
                })
              : [],
          },
        },
        staffList: data?.customer?.staffList
          ? data?.customer?.staffList.map((staff, index) => {
              return {
                name: staff?.name || '',
                value: staff?.value || '',
              };
            })
          : [],
        alimtalkToList: {
          BUSINESS_REGISTRATION_FILE_CHANGED: data?.customer?.alimtalkToList?.BUSINESS_REGISTRATION_FILE_CHANGED
            ? data?.customer?.alimtalkToList?.BUSINESS_REGISTRATION_FILE_CHANGED.map((person) => {
                return {
                  id: person?.id || '',
                  name: person?.name || '',
                  checkedYn: person?.checkedYn ?? false,
                };
              })
            : [],
          DELEGATION_REGISTRATION_BOOKKEEPING_CONTRACT_REQUEST: data?.customer?.alimtalkToList
            ?.DELEGATION_REGISTRATION_BOOKKEEPING_CONTRACT_REQUEST
            ? data?.customer?.alimtalkToList?.DELEGATION_REGISTRATION_BOOKKEEPING_CONTRACT_REQUEST.map((person) => {
                return {
                  id: person?.id || '',
                  name: person?.name || '',
                  checkedYn: person?.checkedYn ?? false,
                };
              })
            : [],
          CMS_ACCOUNT_CONTRACT_REQUEST: data?.customer?.alimtalkToList?.CMS_ACCOUNT_CONTRACT_REQUEST
            ? data?.customer?.alimtalkToList?.CMS_ACCOUNT_CONTRACT_REQUEST.map((person) => {
                return {
                  id: person?.id || '',
                  name: person?.name || '',
                  checkedYn: person?.checkedYn ?? false,
                };
              })
            : [],
        },
      },
      progress: {
        valueAddedTax: data?.progress?.valueAddedTax
          ? {
              selectList: {
                selectedIndex: data?.progress?.valueAddedTax?.selectList?.selectedIndex || null,
                periodList: data?.progress?.valueAddedTax?.selectList?.periodList
                  ? data?.progress?.valueAddedTax?.selectList?.periodList.map((period) => {
                      return {
                        name: period?.name || '',
                        value: period?.value || '',
                        year: period?.year,
                      };
                    })
                  : [],
              },
              steps: data?.progress?.valueAddedTax?.steps
                ? data?.progress?.valueAddedTax?.steps.map((step) => {
                    return {
                      type: step?.type || '',
                      dateTime: step?.dateTime || '',
                      tag: step?.tag || '',
                      selectedYn: step?.selectedYn || false,
                    };
                  })
                : [],
            }
          : null,
        corporateTax: data?.progress?.corporateTax
          ? {
              selectList: {
                selectedIndex: data?.progress?.corporateTax?.selectList?.selectedIndex || null,
                periodList: data?.progress?.corporateTax?.selectList?.periodList
                  ? data?.progress?.corporateTax?.selectList?.periodList.map((period) => {
                      return {
                        name: period?.name || '',
                        year: period?.year,
                      };
                    })
                  : [],
              },
              steps: data?.progress?.corporateTax?.steps
                ? data?.progress?.corporateTax?.steps.map((step) => {
                    return {
                      type: step?.type || '',
                      dateTime: step?.dateTime || '',
                      tag: step?.tag || '',
                      selectedYn: step?.selectedYn || false,
                    };
                  })
                : [],
            }
          : null,
        businessRegistration: data?.progress?.businessRegistration
          ? {
              steps: data?.progress?.businessRegistration?.steps
                ? data?.progress?.businessRegistration?.steps.map((step) => {
                    return {
                      type: step?.type || '',
                      dateTime: step?.dateTime || '',
                      tag: step?.tag || '',
                      selectedYn: step?.selectedYn || false,
                    };
                  })
                : [],
            }
          : null,
      },
    };
  },
  setValueAddedTaxProgress(data) {
    this.business.progress.valueAddedTax.steps = data.steps
      ? data.steps.map((step) => {
          return {
            type: step?.type || '',
            dateTime: step?.dateTime || '',
            tag: step?.tag || '',
            selectedYn: step?.selectedYn || false,
          };
        })
      : [];
  },
  setCorporateTaxProgress(data) {
    this.business.progress.corporateTax.steps = data.steps
      ? data.steps.map((step) => {
          return {
            type: step?.type || '',
            dateTime: step?.dateTime || '',
            tag: step?.tag || '',
            selectedYn: step?.selectedYn || false,
          };
        })
      : [];
  },
});

export default BusinessHomeModel;
