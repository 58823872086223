import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import AvoidBookkeepingContractModalVm from './AvoidBookkeepingContractModalVm';
import clsx from 'clsx';
import queryString from 'query-string';
import st from './AvoidBookkeepingContractModal.module.scss';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';
import { addCommas } from '@common/module/replaceNumber';

let vm;

function AvoidBookkeepingContractModal() {
  useComponentWillMount(() => {
    vm = new AvoidBookkeepingContractModalVm();
  });
  let location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  return useObserver(() => (
    <div className={st.modalContent}>
      <ul>
        <li>
          <div className={st.title}>기장계약 해지 이유</div>
          <div className={st.rowContent}>
            <textarea
              className={st.textarea}
              value={vm.data?.reasonsForTerminatingContract}
              onChange={(e) => vm.reasonsForTerminatingContract(e)}
            ></textarea>
          </div>
        </li>
        <li>
          <div className={st.title}>기장계약 종료일자</div>
          <div className={st.rowContent}>
            <div className={st.datepicker}>
              <DatePicker name="" selected={vm.data?.contractEndDate} onChange={(e) => vm.contractEndDate(e)} />
            </div>
          </div>
        </li>
      </ul>
      <div className={st.buttonArea}>
        <div>
          <button
            className={clsx(st.saveBtn)}
            disabled={!vm.saveActive}
            style={{ width: '100%' }}
            onClick={() => vm.send(businessId)}
          >
            <span>기장계약 해지하기</span>
          </button>
        </div>
      </div>
    </div>
  ));
}
export default AvoidBookkeepingContractModal;
