import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './ProcessVm';
import queryString from 'query-string';
import listSt from '@commonComponents/CheckResultList/CheckResultList.module.scss';
import st from './Process.module.scss';
import clsx from 'clsx';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import MessageHint from '@commonComponents/MessageHint/MessageHint';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import NationalLocalTaxSend from './NationalLocalTaxSend/NationalLocalTaxSend';
import NationalTaxSendFirst from './NationalTaxSendFirst/NationalTaxSendFirst';

function Process() {
  let location = useLocation();
  const businessId = queryString.parse(location.search).businessId;
  const year = queryString.parse(location.search).year;

  useEffect(() => {
    if (businessId) {
      vm.dataLoad(businessId, year);
    }
  }, []);

  return useObserver(() =>
    vm.process ? (
      <div className={listSt.checkResultList}>
        <div className={listSt.result}>
          <div className={clsx(listSt.title, st.pageTitle)}>
            법인세 업무 처리<span className={st.companyName}>{vm.process.businessName}</span>
            <button
              className={clsx(listSt.pageArrowBtn, st.pageTopBtn)}
              type="button"
              onClick={() => vm.openAlimtalkModal(businessId)}
            >
              수동 알림톡 발송
            </button>
          </div>
          <ul>
            <li>
              <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{ width: '190px' }}>
                현재상태
              </div>
              <div className={listSt.listContent}>
                <div>{vm.process.status.replace('_', ' ')}</div>
              </div>
            </li>
            <li>
              <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{ width: '190px' }}>
                자료제출 안내
              </div>
              <div className={listSt.listContent} style={{ display: 'block' }}>
                <div className={st.talkExplain}>
                  <div className={st.title}>자료제출 안내 알림톡은 자동으로 발송됩니다.</div>
                  <div className={clsx(st.content, st.exText)}>
                    타임라인을 확인하고, 자동알림톡이 발송되지 않은 경우에만
                    <br />
                    아래 버튼을 눌러주세요.
                  </div>
                </div>
                <ul className={listSt.flexList}>
                  <li className={listSt.hasBtn}>
                    <div className={listSt.rowTitle}>알림톡 받으실 분</div>
                    <div className={listSt.rowContent}>
                      <ul className={st.checkList} style={{ marginTop: '2px' }}>
                        {vm.process.alimTalkToList.dataSubmission.map((person, index) => (
                          <li key={index}>
                            <CheckBox checked={person.checkedYn} onChange={(e) => vm.dataSubmission(e, index)}>
                              {person.position ? `${person.position} : ` : ''} {person.name}님
                            </CheckBox>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <button
                        style={{ width: '356px' }}
                        type="button"
                        disabled={!vm.dataSubmissionActive}
                        className={listSt.sendBtn}
                        onClick={() => vm.sendDataSubmission(businessId)}
                      >
                        <span>저장 + 자료제출 안내 알림톡 발송</span>
                      </button>
                      <MessageHint text="알림톡 메세지보기">
                        <img src="/images/talkPreview_법인세_자료제출안내.png?1" />
                      </MessageHint>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{ width: '190px' }}>
                자료제출 재촉
              </div>
              <div className={listSt.listContent} style={{ display: 'block' }}>
                <ul className={listSt.flexList}>
                  <li className={listSt.hasBtn}>
                    <div className={listSt.rowTitle}>알림톡 받으실 분</div>
                    <div className={listSt.rowContent}>
                      <ul className={st.checkList} style={{ marginTop: '2px' }}>
                        {vm.process.alimTalkToList.requestingDocument.map((person, index) => (
                          <li key={index}>
                            <CheckBox checked={person.checkedYn} onChange={(e) => vm.requestingDocument(e, index)}>
                              {person.position ? `${person.position} : ` : ''} {person.name}님
                            </CheckBox>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <button
                        style={{ width: '356px' }}
                        type="button"
                        disabled={!vm.requestingDocumentActive}
                        className={listSt.sendBtn}
                        onClick={() => vm.sendRequestingDocument(businessId)}
                      >
                        <span>저장 + 자료제출 재촉 알림톡 발송</span>
                      </button>
                      <MessageHint text="알림톡 메세지보기">
                        <img src="/images/talkPreview_법인세_자료제출재촉.png?1" />
                      </MessageHint>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className={clsx(listSt.ListTitle, listSt.notImportant)} style={{ width: '190px' }}>
                결산업무 진행시작
              </div>
              <div className={listSt.listContent} style={{ display: 'block' }}>
                <ul className={listSt.flexList}>
                  <li className={listSt.hasBtn}>
                    <div className={listSt.rowTitle}>알림톡 받으실 분</div>
                    <div className={listSt.rowContent}>
                      <ul className={st.checkList} style={{ marginTop: '2px' }}>
                        {vm.process.alimTalkToList.settlementWork.map((person, index) => (
                          <li key={index}>
                            <CheckBox checked={person.checkedYn} onChange={(e) => vm.settlementWork(e, index)}>
                              {person.position ? `${person.position} : ` : ''} {person.name}님
                            </CheckBox>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                  <li>
                    <div>
                      <button
                        style={{ width: '356px' }}
                        type="button"
                        disabled={!vm.settlementWorkActive}
                        className={listSt.sendBtn}
                        onClick={() => vm.sendSettlementWork(businessId)}
                      >
                        <span>저장 + 자료수집 완료 & 결산시작 알림톡 발송</span>
                      </button>
                      <MessageHint text="알림톡 메세지보기">
                        <img src="/images/talkPreview_법인세_결산업무진행시작.png?1" />
                      </MessageHint>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <div className={listSt.ListTitle} style={{ width: '190px' }}>
                법인세 신고완료
              </div>
              <div className={listSt.listContent}>
                <ul className={listSt.flexList}>
                  <li>
                    <div className={listSt.rowTitle} style={{ position: 'relative', top: '2px' }}>
                      신고결과 발송순서
                    </div>
                    <div className={listSt.rowContent}>
                      <ul className={listSt.radioList}>
                        <li>
                          <Radio
                            name="taxBillSendWayType"
                            value="NATIONAL_LOCAL_TAX_SEND"
                            checked={vm.process.taxBillSendWayType === 'NATIONAL_LOCAL_TAX_SEND'}
                            onChange={vm.taxBillSendWayType}
                          >
                            국세 + 지방세 함께 보내기
                          </Radio>
                        </li>
                        <li>
                          <Radio
                            name="taxBillSendWayType"
                            value="NATIONAL_TAX_SEND_FIRST"
                            checked={vm.process.taxBillSendWayType === 'NATIONAL_TAX_SEND_FIRST'}
                            onChange={vm.taxBillSendWayType}
                          >
                            국세 먼저 보내기
                          </Radio>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {vm.process.taxBillSendWayType && (
                    <li>
                      <div className={listSt.rowTitle} style={{ position: 'relative', top: '2px' }}>
                        법인세 분납
                      </div>
                      <div className={listSt.rowContent}>
                        <ul className={listSt.radioList}>
                          <li>
                            <Radio
                              name="instalmentYn"
                              value="false"
                              checked={vm.process.instalmentYn === false}
                              onChange={vm.instalmentYn}
                            >
                              분납 신청 X
                            </Radio>
                          </li>
                          <li>
                            <Radio
                              name="instalmentYn"
                              value="true"
                              checked={vm.process.instalmentYn === true}
                              onChange={vm.instalmentYn}
                            >
                              분납 신청 O
                            </Radio>
                          </li>
                        </ul>
                      </div>
                    </li>
                  )}
                  {vm.process.taxBillSendWayType === 'NATIONAL_LOCAL_TAX_SEND' && vm.process.instalmentYn !== null && (
                    <NationalLocalTaxSend businessId={businessId} year={year} />
                  )}
                  {vm.process.taxBillSendWayType === 'NATIONAL_TAX_SEND_FIRST' && vm.process.instalmentYn !== null && (
                    <NationalTaxSendFirst businessId={businessId} year={year} />
                  )}
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    ) : (
      <></>
    ),
  );
}

export default Process;
