import { runInAction } from 'mobx';
import Cookies from 'js-cookie';
import portalModel from '@model/portalModel';
import { dialogModel } from '@model/dialogModel';
import userModel from '@model/userModel';
import presetService from '@service/presetService';

class AppVm {
  get isLogined() {
    if ((Cookies.get('accessToken') && Cookies.get('refreshToken')) || localStorage.getItem('rememberMeToken')) {
      return true;
    } else {
      return false;
    }
  }
  loginedStart() {
    if (!userModel.user.name) {
      presetService.load();
    }
  }
  //popup 관련 함수들
  get popupContent() {
    return portalModel.content;
  }
  get popupTitle() {
    return portalModel.title;
  }
  popupClose() {
    runInAction(() => {
      portalModel.content = null;
      portalModel.title = '';
    });
  }
  get portalModel() {
    return portalModel;
  }
  //dialog 관련 함수들
  get dialogType() {
    return dialogModel.type;
  }
  get dialogTitle() {
    return dialogModel.title;
  }
  get dialogContent() {
    return dialogModel.content;
  }
  get dialogBtn() {
    return dialogModel.btn;
  }
  get dialogAlertBtnText() {
    return dialogModel.alertBtnText;
  }
  get dialogConfirmBtnText() {
    return dialogModel.confirmBtnText;
  }
  get dialogCancelBtnText() {
    return dialogModel.cancelBtnText;
  }
  get dialogContentJSX() {
    return dialogModel.contentJSX;
  }
  get user() {
    return userModel.user;
  }
}
export default new AppVm();
