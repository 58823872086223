import { observable } from 'mobx';

const BusinessListModel = observable({
  columns: [],
  employeeList: [],
  selectedUserId: null,
  setColumns(columns) {
    this.columns = columns || [];
  },
  setSelectedUserId(selectedUserId) {
    this.selectedUserId = selectedUserId || '';
  },
  setEmployeeList(employeeList) {
    this.employeeList = employeeList.map((employee) => {
      return {
        id: employee.id || '',
        name: employee.name || '',
      };
    });
  },
});

export default BusinessListModel;
