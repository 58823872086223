class BusinessNameVm {
  openHistoryWindow(businessId) {
    window.open(
      window.location.origin + `/timeline?businessId=${businessId}`,
      `timeline${businessId}`,
      `width=680, height=900, toolbar=0,location=0,menubar=0`,
    );
  }
  openHomeWindow(businessId) {
    window.open(
      window.location.origin + `/businessHome?businessId=${businessId}`,
      `home${businessId}`,
      `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`,
    );
  }
}
export default new BusinessNameVm();
