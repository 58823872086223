import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import Cookies from 'js-cookie';
import { pageRouter } from '@model/pageModel';
import userModel from '@model/userModel';
import presetService from '@service/presetService';
import { _alert, _confirm } from '@model/dialogModel';
import socketService from '@service/socketService';

class LoginService {
  async login(param, autoLogin) {
    const res = await post(`/login?autoLogin=${autoLogin}`, param);
    if (res.data.loginPermissionYn === false) {
      await _alert('가입대기중 계정입니다. 관리자가 승인 후 사용이 가능합니다.');
    } else {
      this.loginSuccess(res.data);
    }
  }
  loginSuccess(res) {
    /*
            rememberMeToken으로 자동로그인 여부를 판단함.
        */
    if (res.rememberMeToken) {
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      localStorage.setItem('rememberMeToken', res.rememberMeToken);
      localStorage.setItem('accessToken', res.accessToken);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('rememberMeToken');
      Cookies.set('accessToken', res.accessToken);
      Cookies.set('refreshToken', res.refreshToken);
    }
    if (!userModel.user.name) {
      presetService.load();
    }
    window.location.replace(`/business/list`);
  }
  async logout() {
    //로그아웃
    //로그아웃api요청
    let headerParam = {};
    if (Cookies.get('refreshToken')) {
      headerParam['Refresh-Authorization'] = `Bearer ${Cookies.get('refreshToken')}`;
    }
    if (localStorage.getItem('rememberMeToken')) {
      headerParam['Remember-Me-Authorization'] = `Bearer ${localStorage.getItem('rememberMeToken')}`;
    }

    await post(`/user/logout`, {}, headerParam);
    this.logoutClient();
    window.location.replace(`/auth/login`);
  }
  logoutClient() {
    //리프레시토큰에러같은 로그아웃시에는 로그아웃 api는 요청하지않음
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('rememberMeToken');
    socketService.disConnect();
    userModel.removeUser();
  }
}
export default new LoginService();
