import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import ArrayCellRendererVm from './ArrayCellRendererVm';
function ArrayCellRenderer(props) {
  return useObserver(() => (
    <>
      {props.data.managers.map((manager, index) => (
        <div key={index}>{manager}</div>
      ))}
    </>
  ));
}
export default ArrayCellRenderer;
