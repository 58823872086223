import { observable } from 'mobx';

const DelegationRegistrationProcessModel = observable({
  process: null,
  setProcess(data) {
    this.process = {
      cmsAccountButtonActiveYn: data?.cmsAccountButtonActiveYn || false,
      lastStep: data?.lastStep || '',
      companyName: data?.companyName || '',
      address: data?.address || '',
      bookkeepingServiceContract: {
        customerType: data?.bookkeepingServiceContract?.customerType || '',
        bookkeepingServiceStartAt: data?.bookkeepingServiceContract?.bookkeepingServiceStartAt,
        freeBookkeepingServiceEndAt: data?.bookkeepingServiceContract?.freeBookkeepingServiceEndAt,
        bookkeepingServiceFee: data?.bookkeepingServiceContract?.bookkeepingServiceFee ?? '',
        selectableBookkeepingStaffs: data?.bookkeepingServiceContract?.selectableBookkeepingStaffs.map(
          (selectableBookkeepingStaff) => {
            return {
              name: selectableBookkeepingStaff?.name || '',
              id: selectableBookkeepingStaff?.id || '',
            };
          },
        ),
        bookkeepingStaffId: data?.bookkeepingServiceContract?.bookkeepingStaffId || null,
        freeMonth: data?.bookkeepingServiceContract?.freeMonth || null,
        freeMonths: data?.bookkeepingServiceContract?.freeMonths || [],
      },
      company: {
        taxationYn: data?.company?.taxationYn ?? '',
        faithfulnessTaxPayerYn: data?.company?.faithfulnessTaxPayerYn ?? '',
        paydayThisMonthYn: data?.company?.paydayThisMonthYn ?? '',
        payday: data?.company?.payday ?? '',
        selectableVatPreliminaryReturns: data?.company?.selectableVatPreliminaryReturns.map(
          (selectableVatPreliminaryReturn) => {
            return {
              title: selectableVatPreliminaryReturn?.title || '',
              code: selectableVatPreliminaryReturn?.code || '',
            };
          },
        ),
        vatPreliminaryReturn: data?.company?.vatPreliminaryReturn || '',
      },
      businessRegistration: {
        businessRegistrationNumber: data?.businessRegistration?.businessRegistrationNumber || '',
        unregisteredYn: data?.businessRegistration?.unregisteredYn || false,
        businessStartAt: data?.businessRegistration?.businessStartAt,
        issuedAt: data?.businessRegistration?.issuedAt,
        businessTypeAndItemList:
          data?.businessRegistration?.businessTypeAndItemList &&
          data?.businessRegistration?.businessTypeAndItemList.length !== 0
            ? data?.businessRegistration?.businessTypeAndItemList.map((TypeAndItem) => {
                return {
                  type: TypeAndItem.type || '',
                  item: TypeAndItem.item || '',
                };
              })
            : [
                {
                  type: '',
                  item: '',
                },
              ],
        businessRegistrationFile: data?.businessRegistration?.businessRegistrationFile || null,
      },
      delegationRegistrationAlimTalkToList: data?.delegationRegistrationAlimTalkToList.map(
        (delegationRegistrationAlimTalkTo) => {
          return {
            id: delegationRegistrationAlimTalkTo?.id || '',
            name: delegationRegistrationAlimTalkTo?.name || '',
            position: delegationRegistrationAlimTalkTo?.position || '',
            checkedYn: delegationRegistrationAlimTalkTo?.checkedYn || false,
          };
        },
      ),
      cmsAlimTalkToList: data?.cmsAlimTalkToList.map((cmsAlimTalkTo) => {
        return {
          id: cmsAlimTalkTo?.id || '',
          name: cmsAlimTalkTo?.name || '',
          position: cmsAlimTalkTo?.position || '',
          checkedYn: cmsAlimTalkTo?.checkedYn || false,
        };
      }),
      registrationDateString: data?.registrationDateString || '',
    };
  },
});

export default DelegationRegistrationProcessModel;
