import { pageRouter } from '@model/pageModel';
import ValueAddedTaxListModel from '@model/ValueAddedTaxListModel';
import ValueAddedTaxListService from '@service/ValueAddedTaxListService';
import { observable } from 'mobx';

class ValueAddedTaxListVm {
  constructor() {
    this.state = observable({
      activeTab: null,
    });
  }
  load() {
    ValueAddedTaxListModel.columns = [];
    ValueAddedTaxListService.load();
  }
  loadGridFilter(selectedIndex) {
    ValueAddedTaxListService.loadGridFilter(this.state.activeTab, selectedIndex);
  }
  resetLoad() {
    ValueAddedTaxListModel.columns = [];
    ValueAddedTaxListModel.selectedUserId = null;
  }
  get periodList() {
    return ValueAddedTaxListModel.periodList;
  }
  get employeeList() {
    return ValueAddedTaxListModel.employeeList;
  }
  get selectedUserId() {
    return ValueAddedTaxListModel.selectedUserId;
  }
  get columns() {
    return ValueAddedTaxListModel.columns;
  }
  get tabs() {
    return ValueAddedTaxListModel.tabs;
  }
  selectedPeriodIdx(e, selectedIndex) {
    if (e.target.value !== selectedIndex) {
      pageRouter.replace(`/valueAddedTax/list?selectedIndex=${e.target.value}`);
    }
  }
  changeSelectedUserId(e) {
    ValueAddedTaxListModel.selectedUserId = e.target.value;
  }
  gridData(selectedIndex) {
    return {
      getRows: async (params) => {
        const jsonRequest = params.request;

        jsonRequest.tab = this.state.activeTab;
        const selectedUserId = this.selectedUserId;
        const res = await ValueAddedTaxListService.gridData(
          jsonRequest,
          ValueAddedTaxListModel.periodList[selectedIndex],
          selectedUserId,
        );

        params.successCallback(res.rows.data, res.rows.lastRow);
      },
    };
  }
  onDragStopped(e) {
    const sendData = e.columnApi.getColumnState();

    ValueAddedTaxListService.onDragStopped(sendData);
  }
  onChangeData(e, selectedIndex) {
    const sendData = {
      type: e.type,
      pinned: e.pinned,
      visible: e.visible,
      colIds: e?.columns?.map((column) => column.colId),
      value: e.value,
      colId: e?.column?.colId,
      rowData: e.data,
    };
    const selectedUserId = this.selectedUserId;

    ValueAddedTaxListService.onChangeData(sendData, ValueAddedTaxListModel.periodList[selectedIndex], selectedUserId);
  }
  onTab(valueAddedTaxStatusType, selectedIndex) {
    if (valueAddedTaxStatusType) {
      pageRouter.replace(`/valueAddedTax/list?type=${valueAddedTaxStatusType}&selectedIndex=${selectedIndex}`);
    } else {
      pageRouter.replace(`/valueAddedTax/list?selectedIndex=${selectedIndex}`);
    }
  }
  onGridPageSizeChanged(e, gridRef) {
    const value = e.target.value;

    gridRef.current.api.paginationSetPageSize(Number(value));
  }
}

export default new ValueAddedTaxListVm();
