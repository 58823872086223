import { observable } from 'mobx';

const notificationModel = observable({
  notifications: [],
  setNotifications(notifications) {
    this.notifications = notifications.map((notification) => {
      return {
        acceptAt: notification.acceptAt || null,
        acceptYn: notification.acceptYn || null,
        callbackUrl: notification.callbackUrl || '',
        content: notification.content || '',
        createdAt: notification.createdAt || '',
        notificationId: notification.notificationId || null,
        notificationType: notification.notificationType || null,
        readAt: notification.readAt || null,
        id: notification.id || null,
      };
    });
  },
});

export default notificationModel;
