import { post, get } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import StaffListModel from '@model/StaffListModel';

class StaffListService {
  async load() {
    const res = await get(`/auth/management/employeeList`);
    StaffListModel.setEmployees(res.data);
  }
}
export default new StaffListService();
