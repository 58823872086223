import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import StockholderRegisterRequestModalVm from './StockholderRegisterRequestModalVm';
import clsx from 'clsx';
import queryString from 'query-string';
import st from './StockholderRegisterRequestModal.module.scss';
import useComponentWillMount from '@common/hook/useComponentWillMount';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';

let vm;

function StockholderRegisterRequestModal() {
  useComponentWillMount(() => {
    vm = new StockholderRegisterRequestModalVm();
  });
  let location = useLocation();
  const businessId = queryString.parse(location.search).businessId;

  return useObserver(() => (
    <div className={st.modalContent}>
      <div className={st.topText}>
        <ul className={st.exList}>
          <li>법인세 신고를 위한 주주명부를 요청할 수 있어요.</li>
          <li>
            {vm.businessName} : {vm.data.shareholder.settlementMonth}월 결산법인
          </li>
        </ul>
      </div>
      <div className={st.dateContent}>
        <span style={{ marginRight: '55px' }}>주주명부 일자</span>
        <Select
          name=""
          style={{ width: '125px' }}
          value={vm.data.shareholder.selectedYears}
          onChange={(e) => vm.years(e)}
        >
          <option value="">-</option>
          {vm.data.shareholder.years.map((year, index) => (
            <option key={index} value={year}>
              {year}
            </option>
          ))}
        </Select>
        <span style={{ marginLeft: '16px' }}>
          {vm.data.shareholder.settlementMonth}월 {vm.data.shareholder.lastDay}일자
        </span>
      </div>
      <div className={st.buttonArea}>
        <div>
          <button
            className={clsx(st.saveBtn)}
            disabled={!vm.saveActive}
            style={{ width: '100%' }}
            onClick={() => vm.save(businessId)}
          >
            <span>주주명부 요청하기</span>
          </button>
        </div>
        <span className={st.ex}>요청버튼 누른 후, 1~3분 걸려요.</span>
      </div>
    </div>
  ));
}
export default StockholderRegisterRequestModal;
