import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import AuthVm from './AuthVm';
import Login from './Login/Login';
import Join from './Join/Join';
import FindPassword from './FindPassword/FindPassword';
import SetupNewPassword from './SetupNewPassword/SetupNewPassword';
import ChangeEmail from './ChangeEmail/ChangeEmail';
import { Route, Routes, useNavigate } from 'react-router-dom';

function Auth() {
  return useObserver(() => (
    <Routes>
      {/* 로그인*/}
      <Route path="/Login" element={<Login />} />
      {/* 회원가입*/}
      <Route path="/Join" element={<Join />} />
      {/* 비밀번호 찾기*/}
      <Route path="/FindPassword" element={<FindPassword />} />
      {/* 비밀번호 리셋*/}
      <Route path="/setupNewPassword" element={<SetupNewPassword />} />
      {/* 이메일변경*/}
      <Route path="/changeEmail" element={<ChangeEmail />} />
    </Routes>
  ));
}
export default Auth;
