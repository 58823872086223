import { action, makeObservable, observable } from 'mobx';

class LoadingModel {
  isLoading = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      on: action,
      off: action,
    });
  }
  on() {
    this.isLoading = true;
  }
  off() {
    this.isLoading = false;
  }
}

export default new LoadingModel();
