import { observable } from 'mobx';

const ValueAddedTaxModel = observable({
  process: null,
  setProcess(data) {
    this.process = {
      vatId: data?.vatId || '',
      vatDetailDto: {
        scheduledReportProgressStatus: data?.vatDetailDto?.scheduledReportProgressStatus || null,
        isFinalPage: data?.vatDetailDto?.isFinalPage || false,
        companyName: data?.vatDetailDto?.companyName || '',
        status: data?.vatDetailDto?.status || '',
        promptForDataSubmissionAlimTalkToList: this.returnArray(
          data?.vatDetailDto?.promptForDataSubmissionAlimTalkToList,
        ).map((item) => {
          return {
            id: item?.id || '',
            name: item?.name || '',
            position: item?.position || '',
            checkedYn: item?.checkedYn || false,
          };
        }),
        readyToFileVatAlimTalkToList: this.returnArray(data?.vatDetailDto?.readyToFileVatAlimTalkToList).map((item) => {
          return {
            id: item?.id || '',
            name: item?.name || '',
            position: item?.position || '',
            checkedYn: item?.checkedYn || false,
          };
        }),
        vat: {
          taxAmountOfNotice: data?.vatDetailDto?.vat?.taxAmountOfNotice ?? '',
          dueDateForTaxAmountOfNotice: this.setDate(data?.vatDetailDto?.vat?.dueDateForTaxAmountOfNotice),
          alimTalkToList: this.returnArray(data?.vatDetailDto?.vat?.alimTalkToList).map((item) => {
            return {
              id: item?.id || '',
              name: item?.name || '',
              position: item?.position || '',
              checkedYn: item?.checkedYn || false,
            };
          }),
        },
        vatReturnCompleted: {
          statementOfPayment: data?.vatDetailDto?.vatReturnCompleted?.statementOfPayment
            ? {
                id: data?.vatDetailDto?.vatReturnCompleted?.statementOfPayment?.id || '',
                name: data?.vatDetailDto?.vatReturnCompleted?.statementOfPayment?.fileName || '',
                downloadUrl: data?.vatDetailDto?.vatReturnCompleted?.statementOfPayment?.downloadUrl || '',
              }
            : null,
          statementOfVatReturn: data?.vatDetailDto?.vatReturnCompleted?.statementOfVatReturn
            ? {
                id: data?.vatDetailDto?.vatReturnCompleted?.statementOfVatReturn?.id || '',
                name: data?.vatDetailDto?.vatReturnCompleted?.statementOfVatReturn?.fileName || '',
                downloadUrl: data?.vatDetailDto?.vatReturnCompleted?.statementOfVatReturn?.downloadUrl || '',
              }
            : null,
          etcFile: {
            downloadUrl: data?.vatDetailDto?.vatReturnCompleted?.etcFile?.downloadUrl || '',
            files: data?.vatDetailDto?.vatReturnCompleted?.etcFile?.files
              ? data?.vatDetailDto?.vatReturnCompleted?.etcFile?.files.map((file) => {
                  return {
                    fileName: file?.fileName || '',
                  };
                })
              : [],
          },
          purchaseAndSalesLedger: {
            downloadUrl: data?.vatDetailDto?.vatReturnCompleted?.purchaseAndSalesLedger?.downloadUrl || '',
            files: data?.vatDetailDto?.vatReturnCompleted?.purchaseAndSalesLedger?.files
              ? data?.vatDetailDto?.vatReturnCompleted?.purchaseAndSalesLedger?.files.map((file) => {
                  return {
                    fileName: file?.fileName || '',
                  };
                })
              : [],
          },
          vat: {
            salesTax: data?.vatDetailDto?.vatReturnCompleted?.vat?.salesTax ?? '',
            purchaseTax: data?.vatDetailDto?.vatReturnCompleted?.vat?.purchaseTax ?? '',
            totalDeductionTaxAmount: data?.vatDetailDto?.vatReturnCompleted?.vat?.totalDeductionTaxAmount ?? '',
            additionalTaxAmountPaid: data?.vatDetailDto?.vatReturnCompleted?.vat?.additionalTaxAmountPaid ?? '',
            paymentPeriod: this.setDate(data?.vatDetailDto?.vatReturnCompleted?.vat?.paymentPeriod),
            nextPreliminaryReturnType: data?.vatDetailDto?.vatReturnCompleted?.vat?.nextPreliminaryReturnType ?? '',
            showNextPreliminaryReturnTypeYn:
              data?.vatDetailDto?.vatReturnCompleted?.vat?.showNextPreliminaryReturnTypeYn || false,
            nextPreliminaryReturnTypeList:
              data?.vatDetailDto?.vatReturnCompleted?.vat?.nextPreliminaryReturnTypeList.map((item) => {
                return {
                  title: item?.title || '',
                  type: item?.type || '',
                };
              }),
          },
          alimTalkToList: this.returnArray(data?.vatDetailDto?.vatReturnCompleted?.alimTalkToList).map((item) => {
            return {
              id: item?.id || '',
              name: item?.name || '',
              position: item?.position || '',
              checkedYn: item?.checkedYn || false,
            };
          }),
        },
        scheduledReportProgress: {
          dataSubmissionAlimTalkToList: this.returnArray(
            data?.vatDetailDto?.scheduledReportProgress?.dataSubmissionAlimTalkToList,
          ).map((item) => {
            return {
              id: item?.id || '',
              name: item?.name || '',
              position: item?.position || '',
              checkedYn: item?.checkedYn || false,
            };
          }),
          promptForDataSubmissionAlimTalkToList: this.returnArray(
            data?.vatDetailDto?.scheduledReportProgress?.promptForDataSubmissionAlimTalkToList,
          ).map((item) => {
            return {
              id: item?.id || '',
              name: item?.name || '',
              position: item?.position || '',
              checkedYn: item?.checkedYn || false,
            };
          }),
          readyToFileVatAlimTalkToList: this.returnArray(
            data?.vatDetailDto?.scheduledReportProgress?.readyToFileVatAlimTalkToList,
          ).map((item) => {
            return {
              id: item?.id || '',
              name: item?.name || '',
              position: item?.position || '',
              checkedYn: item?.checkedYn || false,
            };
          }),
          vatReturnCompleted: {
            statementOfPayment: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfPayment
              ? {
                  id: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfPayment?.id || '',
                  name:
                    data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfPayment?.fileName || '',
                  downloadUrl:
                    data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfPayment?.downloadUrl ||
                    '',
                }
              : null,
            statementOfVatReturn: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfVatReturn
              ? {
                  id: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfVatReturn?.id || '',
                  name:
                    data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfVatReturn?.fileName ||
                    '',
                  downloadUrl:
                    data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.statementOfVatReturn
                      ?.downloadUrl || '',
                }
              : null,
            vat: {
              salesTax: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.vat?.salesTax ?? '',
              purchaseTax: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.vat?.purchaseTax ?? '',
              additionalTaxAmountPaid:
                data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.vat?.additionalTaxAmountPaid ?? '',
              paymentPeriod: this.setDate(
                data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.vat?.paymentPeriod,
              ),
              earlyRefundYn:
                data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.vat?.earlyRefundYn || false,
            },
            etcFile: {
              downloadUrl: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.etcFile?.downloadUrl || '',
              files: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.etcFile?.files
                ? data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.etcFile?.files.map((file) => {
                    return {
                      fileName: file?.fileName || '',
                    };
                  })
                : [],
            },
            purchaseAndSalesLedger: {
              downloadUrl:
                data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.purchaseAndSalesLedger?.downloadUrl ||
                '',
              files: data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.purchaseAndSalesLedger?.files
                ? data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.purchaseAndSalesLedger?.files.map(
                    (file) => {
                      return {
                        fileName: file?.fileName || '',
                      };
                    },
                  )
                : [],
            },
            alimTalkToList: this.returnArray(
              data?.vatDetailDto?.scheduledReportProgress?.vatReturnCompleted?.alimTalkToList,
            ).map((item) => {
              return {
                id: item?.id || '',
                name: item?.name || '',
                position: item?.position || '',
                checkedYn: item?.checkedYn || false,
              };
            }),
          },
        },
      },
    };
  },
  set예정신고X_예정고지O(data) {
    this.process.vatDetailDto.vat.alimTalkToList = data?.vatDetailDto?.alimTalkToList.map((item) => {
      return {
        id: item?.id || '',
        name: item?.name || '',
        position: item?.position || '',
        checkedYn: item?.checkedYn || false,
      };
    });
    this.process.vatDetailDto.vat.dueDateForTaxAmountOfNotice = this.setDate(data?.vatDetailDto?.paymentPeriod);
  },
  set예정신고O(data) {
    this.process.vatDetailDto.scheduledReportProgress.dataSubmissionAlimTalkToList =
      data?.vatDetailDto?.dataSubmissionAlimTalkToList.map((item) => {
        return {
          id: item?.id || '',
          name: item?.name || '',
          position: item?.position || '',
          checkedYn: item?.checkedYn || false,
        };
      });
    this.process.vatDetailDto.scheduledReportProgress.promptForDataSubmissionAlimTalkToList =
      data?.vatDetailDto?.promptForDataSubmissionAlimTalkToList.map((item) => {
        return {
          id: item?.id || '',
          name: item?.name || '',
          position: item?.position || '',
          checkedYn: item?.checkedYn || false,
        };
      });
    this.process.vatDetailDto.scheduledReportProgress.readyToFileVatAlimTalkToList =
      data?.vatDetailDto?.readyToFileVatAlimTalkToList.map((item) => {
        return {
          id: item?.id || '',
          name: item?.name || '',
          position: item?.position || '',
          checkedYn: item?.checkedYn || false,
        };
      });
    this.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.alimTalkToList =
      data?.vatDetailDto?.alimTalkToList.map((item) => {
        return {
          id: item?.id || '',
          name: item?.name || '',
          position: item?.position || '',
          checkedYn: item?.checkedYn || false,
        };
      });
    this.process.vatDetailDto.scheduledReportProgress.vatReturnCompleted.vat.paymentPeriod = this.setDate(
      data?.vatDetailDto?.paymentPeriod,
    );
  },
  setDate(date) {
    if (!date?.year) {
      return null;
    }
    if (date) {
      return date.year + '-' + date.month + '-' + date.day;
    } else {
      return null;
    }
  },
  returnArray(_array) {
    if (!_array) {
      return [];
    } else {
      return _array;
    }
  },
});

export default ValueAddedTaxModel;
