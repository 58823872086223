function addCommas(x) {
  if (x || x === 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return '';
  }
}

function removeCommas(x) {
  if (x) {
    return x.replace(/,/g, '');
  }
  return x;
}

function onlyNumber(x) {
  if (x === ' ') {
    return ' ';
  } else {
    return x.replace(/[^-\.0-9]/g, '');
  }
}

function percent(x) {
  return x.toFixed(2) + ' %';
}

export { addCommas, removeCommas, onlyNumber, percent };
