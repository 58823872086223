import { useObserver } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import vm from './SetupNewPasswordVm';
import st from './SetupNewPassword.module.scss';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import Error from '@standby/common-ui/components/molecules/ErrorValidation/ErrorValidation';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import queryString from 'query-string';
import partner from '@common/module/partner';

function SetupNewPassword() {
  let location = useLocation();
  const token = queryString.parse(location.search).token;

  return useObserver(() => (
    <div className={st.setupNewPassword}>
      <form className={st.setupNewPasswordForm} onSubmit={(e) => vm.submit(e, token)}>
        <div className={st.logo}>
          {partner.type === partner.바른 && <img src="/images/loginLogo_barun.svg" alt="바른 로고" />}
          {partner.type === partner.진평 && <img src="/images/loginLogo_jp.svg" alt="진평 로고" />}
        </div>
        <div className={st.explain}>
          <div className={st.title}>새로운 비밀번호를 설정해주세요.</div>
        </div>
        <div className={st.inputRow}>
          <div className={st.label}>
            <label htmlFor="password">새 비밀번호</label>
          </div>
          <InputText
            name="password"
            fullSize
            type="password"
            id="password"
            value={vm.state.password}
            onChange={(e) => vm.changePassword(e)}
            errorText={
              <Error
                name="password"
                value={vm.state.password}
                errorCase={{
                  required: '비밀번호를 입력하세요.',
                  pattern: {
                    value:
                      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?])[A-Za-z\d`~!@#\$%\^&\*\(\)\{\}\[\]\-_=\+\\|;:'"<>,\./\?]{8,16}$/.test(
                        vm.state.password,
                      ),
                    message: '올바른 비밀번호 형식으로 입력하세요',
                  },
                }}
              />
            }
          />
          <ul className={st.passwordEx}>
            <li>8글자 이상 입력</li>
            <li>영문, 숫자, 특수문자 각 1개 이상 사용</li>
          </ul>
        </div>
        <div className={st.inputRow}>
          <div className={st.label}>
            <label htmlFor="password">새 비밀번호 확인</label>
          </div>
          <InputText
            name="passwordCheck"
            fullSize
            type="password"
            id="passwordCheck"
            value={vm.state.passwordCheck}
            onChange={(e) => vm.changePasswordCheck(e)}
            errorText={
              <Error
                name="passwordCheck"
                value={vm.state.passwordCheck}
                errorCase={{
                  required: '비밀번호 확인을 입력하세요',
                  validate: {
                    func: vm.state.password === vm.state.passwordCheck,
                    message: '동일한 비밀번호를 입력하세요.',
                  },
                }}
              />
            }
          />
        </div>
        <div className={st.btnArea}>
          <SquareBtn className={st.submit} type="submit" color="white">
            비밀번호 변경
          </SquareBtn>
        </div>
      </form>
    </div>
  ));
}
export default SetupNewPassword;
