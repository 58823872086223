import { observable } from 'mobx';

const TimelineModel = observable({
  normalTimeLines: [],
  pinnedTimeLines: [],
  setNormalTimeLines(normalTimeLines) {
    this.normalTimeLines = normalTimeLines.map((normalTimeLine) => {
      return {
        alimtalkContent: normalTimeLine?.alimtalkContent || '',
        automaticYn: normalTimeLine?.automaticYn || false,
        createdAt: this.setCreatedAt(normalTimeLine?.createdAt),
        creator: normalTimeLine?.creator || '',
        id: normalTimeLine?.id || null,
        message: normalTimeLine?.message || '',
        pinned: normalTimeLine?.pinned || false,
        timelineType: normalTimeLine?.timelineType || null,
        creatorType: normalTimeLine?.creatorType || null,
      };
    });
  },
  setPinnedTimeLines(pinnedTimeLines) {
    this.pinnedTimeLines = pinnedTimeLines.map((pinnedTimeLine) => {
      return {
        alimtalkContent: pinnedTimeLine?.alimtalkContent || '',
        automaticYn: pinnedTimeLine?.automaticYn || false,
        createdAt: this.setCreatedAt(pinnedTimeLine?.createdAt),
        creator: pinnedTimeLine?.creator || '',
        id: pinnedTimeLine?.id || null,
        message: pinnedTimeLine?.message || '',
        timelineType: pinnedTimeLine?.timelineType || null,
        creatorType: pinnedTimeLine?.creatorType || null,
      };
    });
  },
  setCreatedAt(createdAt) {
    if (createdAt) {
      return (
        createdAt.year +
        ''.replace('20', '') +
        '.' +
        createdAt.month +
        '.' +
        createdAt.day +
        '. ' +
        createdAt.hour +
        ':' +
        createdAt.minute
      );
    }
    return null;
  },
});

export default TimelineModel;
