class ObjectCellRenderer {
  init(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<span style="overflow: hidden; text-overflow: ellipsis">${params.value.name}</span>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(params) {
    return false;
  }
}
export default ObjectCellRenderer;
