import BusinessRegistrationManagementService from '@service/BusinessRegistrationManagementService';
import BusinessRegistrationManagementModel from '@model/BusinessRegistrationManagementModel';
import { get, post } from '@common/module/httpRequest';
import { runInAction, observable, toJS } from 'mobx';
import { pageRouter } from '@model/pageModel';
import { _alert } from '@model/dialogModel';
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';

class BusinessRegistrationManagementVm {
  constructor() {
    this.state = observable({
      activeTab: null,
    });
  }
  load() {
    BusinessRegistrationManagementModel.columns = [];
    BusinessRegistrationManagementService.load();
  }
  get columns() {
    return BusinessRegistrationManagementModel.columns;
  }
  get tabs() {
    return BusinessRegistrationManagementModel.tabs;
  }
  onTab(businessRegistrationStatusType, setFilterModel) {
    if (businessRegistrationStatusType) {
      pageRouter.replace(`/BusinessRegistration/Management?type=${businessRegistrationStatusType}`);
    } else {
      pageRouter.replace(`/BusinessRegistration/Management`);
    }
  }
  gridData(server) {
    return {
      getRows: async (params) => {
        let jsonRequest = params.request;
        jsonRequest.tab = this.state.activeTab;
        const res = await BusinessRegistrationManagementService.gridData(jsonRequest);
        params.successCallback(res.rows.data, res.rows.lastRow);
      },
    };
  }
  onDragStopped(e) {
    const sendData = e.columnApi.getColumnState();
    BusinessRegistrationManagementService.onDragStopped(sendData);
  }
  onChangeData(e) {
    const sendData = {
      type: e.type,
      pinned: e.pinned,
      visible: e.visible,
      colIds: e?.columns?.map((column) => column.colId),
      value: e.value,
      colId: e?.column?.colId,
      rowData: e.data,
    };
    BusinessRegistrationManagementService.onChangeData(sendData);
  }
  onGridPageSizeChanged(e, gridRef) {
    const value = e.target.value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }
  openAlimtalkModal(gridRef) {
    let selectList = [];
    gridRef.current.api.forEachNode((node) => {
      if (node.selected) {
        selectList.push(node.data.businessId);
      }
    });
    if (selectList.length === 0) {
      return _alert('발송대상 법인을 선택해주세요.');
    } else {
      runInAction(() => {
        portalModel.title = '수동 알림톡 발송';
        portalModel.content = <AlimtalkSendModal businessIds={selectList} />;
      });
    }
  }
}
export default new BusinessRegistrationManagementVm();
