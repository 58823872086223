import { runInAction } from 'mobx';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { pageRouter } from '@model/pageModel';
import Cookies from 'js-cookie';
import notificationModel from '@model/notificationModel';

class socketService {
  constructor() {
    this.ws = null;
  }
  async load(channelList) {
    const baseURL = process.env.REACT_APP_API_URL || 'empty';
    this.ws = Stomp.over(new SockJS(`${baseURL}ws`));
    const ws = this.ws;
    ws.connect({}, function (frame) {
      channelList.forEach((channel) => {
        switch (channel.type) {
          case 'notification':
            notificationModel.setNotifications(channel.data);
            ws.subscribe(channel.channelId, function (message) {
              notificationModel.setNotifications(JSON.parse(message.body)?.notifications);
            });
            break;
        }
      });
    });
  }
  async disConnect() {
    if (this.ws) {
      this.ws.disconnect();
    }
  }
}
export default new socketService();
