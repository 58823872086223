import josa from '@common/module/josa';
import BusinessRegistrationModel from '@model/BusinessRegistrationModel';
import { _alert, _confirm } from '@model/dialogModel';
import LoadingModel from '@model/LoadingModel';
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';
import BusinessRegistrationService from '@service/BusinessRegistrationService';
import { observable, runInAction } from 'mobx';

import st from './BusinessRegistration.module.scss';
import CancelReason from './CancelReason/CancelReason';

class BusinessRegistrationVm {
  constructor() {
    this.state = observable({
      cancelReason: '',
    });
  }
  changeTaxCertificateFile(file) {
    BusinessRegistrationModel.businessRegistrationProcessing.taxCertificateFile = file;
  }
  changeBusinessRegistrationCompletionFile(file) {
    BusinessRegistrationModel.businessRegistrationProcessing.businessRegistrationCompletionFile = file;
  }
  dataLoad(businessId) {
    BusinessRegistrationService.load(businessId);
  }
  get businessRegistration() {
    return BusinessRegistrationModel.businessRegistration;
  }
  get businessRegistrationProcessing() {
    return BusinessRegistrationModel.businessRegistrationProcessing;
  }
  async fileDownload(url) {
    try {
      LoadingModel.on();
      await BusinessRegistrationService.fileDownload(url);
      LoadingModel.off();
    } catch (err) {
      LoadingModel.off();
    }
  }
  statusText(status) {
    switch (status) {
      case '등기완료_후_가입대기':
        return '등기완료 후 가입대기';
      case '정보_입력':
        return '가입완료 후 정보 입력 중';
      case '정보입력_완료':
        return '정보입력 완료';
      case '세무서_접수':
        return '세무서 접수완료';
      case '사업자등록_완료':
        return '사업자등록 완료';
      case '사업자등록_취소':
        return '사업자등록 취소';
    }
  }
  openDelegationRegistrationWindow(businessId) {
    window.open(
      window.location.origin + `/delegationRegistration/process?businessId=${businessId}`,
      `DelegationRegistrationProcessing`,
      `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`,
    );
  }
  async changeStatus(businessId, status) {
    this.state.cancelReason = '';
    if (status === '열람기간_연장') {
      if (
        await _confirm(
          `${BusinessRegistrationModel.businessRegistration.companyName}의 정보 열람기간을 3개월 연장하나요?`,
          '',
          '예',
          '아니오',
          <ul className={st.confirmList}>
            <li>연장을 하면 열람기간이 3개월 연장되어요.</li>
            <li>연장을 하지 않는다면, 사업자등록연결이 된 시점부터 3개월 후에는 열람이 불가능해요.</li>
            <li>이용등록(수임등록)을 마친 경우, 제한 없이 정보열람이 가능해요.</li>
            <li>버튼을 누르기 전, 고객으로부터 열람기간을 3개월 연장하는 것에 대한 구두승낙을 받아주세요.</li>
          </ul>,
        )
      ) {
        BusinessRegistrationService.changeStatus(businessId, status);
      }
    } else {
      if (
        await _confirm(
          `${BusinessRegistrationModel.businessRegistration.companyName}의 사업자등록 진행을 취소하나요?`,
          '',
          '예',
          '아니오',
          <>
            <ul className={st.confirmList}>
              <li>진행취소를 한 후부터 정보열람이 불가능해요.</li>
            </ul>
            <CancelReason value={this.state.cancelReason} onChange={(e) => this.cancelReason(e)} />
          </>,
        )
      ) {
        if (!this.state.cancelReason) {
          _alert('취소사유를 입력하세요.');
        } else {
          BusinessRegistrationService.changeStatus(businessId, status, this.state.cancelReason);
        }
      }
    }
  }
  cancelReason(e) {
    this.state.cancelReason = e.target.value;
  }
  taxCertificateAlimTalkToList(e, index) {
    this.businessRegistrationProcessing.taxCertificateAlimTalkToList[index].checkedYn = e.target.checked;
  }
  businessRegistrationCompletionAlimTalkToList(e, index) {
    this.businessRegistrationProcessing.businessRegistrationCompletionAlimTalkToList[index].checkedYn =
      e.target.checked;
  }
  get taxCertificateAlimTalkActive() {
    const businessRegistrationProcessing = BusinessRegistrationModel.businessRegistrationProcessing;
    let active = true;

    if (businessRegistrationProcessing.taxCertificateFile === null) {
      active = false;
    }

    const checkedYn = businessRegistrationProcessing.taxCertificateAlimTalkToList.some(
      (taxCertificateAlimTalkToList) => {
        return taxCertificateAlimTalkToList.checkedYn === true;
      },
    );

    if (checkedYn === false) {
      active = false;
    }

    return active;
  }
  async taxOfficeReceptionNotice(businessId) {
    try {
      LoadingModel.on();
      await BusinessRegistrationService.taxOfficeReceptionNotice(businessId);
      LoadingModel.off();
      await _alert(
        '세무서 접수안내 알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      window.location.reload();
    } catch (err) {
      LoadingModel.off();
    }
  }
  businessRegistrationNumber(e) {
    BusinessRegistrationModel.businessRegistrationProcessing.businessRegistrationNumber = e.target.value.replace(
      /(\d{3})(\d{2})(\d{5})/,
      '$1-$2-$3',
    );
  }
  customerDirectReceiptYn(e) {
    BusinessRegistrationModel.businessRegistrationProcessing.customerDirectReceiptYn = e.target.checked;
  }
  dateOfBusinessCommencementByAccounting(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    BusinessRegistrationModel.businessRegistrationProcessing.dateOfBusinessCommencementByAccounting = date;
  }
  issuedAt(e) {
    let date = null;

    if (e) {
      const year = e.getFullYear();
      const month = ('0' + (e.getMonth() + 1)).slice(-2);
      const day = ('0' + e.getDate()).slice(-2);

      date = year + '-' + month + '-' + day;
    }

    BusinessRegistrationModel.businessRegistrationProcessing.issuedAt = date;
  }
  businessType(e, index) {
    BusinessRegistrationModel.businessRegistrationProcessing.businessTypeAndItemList[index].type = e.target.value;
  }
  businessItem(e, index) {
    BusinessRegistrationModel.businessRegistrationProcessing.businessTypeAndItemList[index].item = e.target.value;
  }
  addBusinessTypeAndItem(index) {
    BusinessRegistrationModel.businessRegistrationProcessing.businessTypeAndItemList.push({
      type: '',
      item: '',
    });
  }
  removeBusinessTypeAndItem(index) {
    BusinessRegistrationModel.businessRegistrationProcessing.businessTypeAndItemList.splice(index, 1);
  }
  get completeNoticeActive() {
    const businessRegistrationProcessing = BusinessRegistrationModel.businessRegistrationProcessing;
    let active = true;
    const checkedYn = businessRegistrationProcessing.businessRegistrationCompletionAlimTalkToList.some(
      (businessRegistrationCompletionAlimTalkToList) => {
        return businessRegistrationCompletionAlimTalkToList.checkedYn === true;
      },
    );

    if (checkedYn === false) {
      active = false;
    }
    if (businessRegistrationProcessing.customerDirectReceiptYn === true) {
      return active;
    }
    if (businessRegistrationProcessing.businessRegistrationCompletionFile === null) {
      active = false;
    }
    if (
      !businessRegistrationProcessing.businessRegistrationNumber ||
      !businessRegistrationProcessing.dateOfBusinessCommencementByAccounting ||
      !businessRegistrationProcessing.issuedAt
    ) {
      active = false;
    }
    if (businessRegistrationProcessing.businessTypeAndItemList.length === 0) {
      active = false;
    }

    return active;
  }
  async sendCompleteNotice(businessId) {
    try {
      LoadingModel.on();
      await BusinessRegistrationService.sendCompleteNotice(businessId);
      LoadingModel.off();
      await _alert(
        '사업자등록 완료안내 알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      window.location.reload();
    } catch (err) {
      LoadingModel.off();
    }
  }
  async customerReceipt(businessId) {
    try {
      LoadingModel.on();
      await BusinessRegistrationService.customerReceipt(businessId);
      LoadingModel.off();
      await _alert(
        '사업자등록 완료안내 알림톡 발송을 시작합니다.',
        '발송 성공여부를 오른쪽 위 [알림]창에서 알려드릴게요.\n (실패하는 경우 재발송을 눌러주세요!)',
      );
      if (window?.opener?.location) {
        window.opener.location.reload();
      }

      window.location.reload();
    } catch (err) {
      LoadingModel.off();
    }
  }
  async saveComplete(businessId) {
    try {
      LoadingModel.on();
      await BusinessRegistrationService.saveComplete(businessId);
      LoadingModel.off();
      await _alert('저장되었습니다.');
    } catch (err) {
      LoadingModel.off();
    }
  }
  async saveTaxOfficeReception(businessId) {
    try {
      LoadingModel.on();
      await BusinessRegistrationService.saveTaxOfficeReception(businessId);
      LoadingModel.off();
      await _alert('저장되었습니다.');
    } catch (err) {
      LoadingModel.off();
    }
  }
  get text() {
    const corpType = BusinessRegistrationModel.businessRegistration.corpType;

    switch (corpType) {
      case '주식회사':
        return '주주';
      case '유한회사':
      case '유한책임회사':
        return '사원';
      default:
        return '주주';
    }
  }
  josa(text) {
    const _text = josa(text, '와과');

    return _text;
  }
  openAlimtalkModal(businessId) {
    runInAction(() => {
      portalModel.title = '수동 알림톡 발송';
      portalModel.content = <AlimtalkSendModal businessId={businessId} />;
    });
  }
  hometaxAcceptanceConsentState() {
    let text = '';
    const hometaxDelegationAcceptanceYn =
      BusinessRegistrationModel.businessRegistrationProcessing.hometaxDelegationAcceptanceYn;
    
    if (hometaxDelegationAcceptanceYn === true) {
      text = '수임동의 O';
    } else if (hometaxDelegationAcceptanceYn === false) {
      text = '수임동의 X';
    } else {
      text = '-';
    }

    return text;
  }
  deletePurposeNumber(purpose) {
    const _purpose = purpose.split('.').slice(1).join('.').trim();

    return _purpose;
  }
  get phoneNumber() {
    const phoneNumber = BusinessRegistrationModel.businessRegistration.telephoneNo.replace(
      /^010(\d{4})(\d{4})/,
      '010-$1-$2',
    );

    return phoneNumber;
  }
}

export default new BusinessRegistrationVm();
