import { useObserver } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';
import { useEffect } from 'react';
import st from './PageNav.module.scss';
function PageNav(props) {
  return useObserver(() => (
    <div className={st.pageNav}>
      {props.nav.map((nav, index) => (
        <span key={index}>{nav}</span>
      ))}
    </div>
  ));
}
export default PageNav;
