import { patch, put } from '@common/module/httpRequest';
import { _alert } from '@model/dialogModel';
import BusinessHomeService from '@service/BusinessHomeService';
import BusinessRegistrationService from '@service/BusinessRegistrationService';

class ModalEditService {
  async edit(param, businessId, parent = 'home') {
    switch (parent) {
      case 'home':
        await put(`/business/${businessId}/home`, param);
        await BusinessHomeService.load(businessId);
        break;
      case 'businessRegistration':
        await patch(`/business/${businessId}/businessRegistration/appliedData`, param);
        await BusinessRegistrationService.load(businessId);
        break;
    }

    await _alert('수정되었습니다.');
  }
}

export default new ModalEditService();
