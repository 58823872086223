import DelegationRegistrationListModel from '@model/DelegationRegistrationListModel';
import { pageRouter } from '@model/pageModel';
import DelegationRegistrationListService from '@service/DelegationRegistrationListService';
import { observable } from 'mobx';

class DelegationRegistrationListVm {
  constructor() {
    this.state = observable({
      activeTab: null,
    });
  }
  load() {
    DelegationRegistrationListModel.columns = [];
    DelegationRegistrationListService.load();
  }
  openWindow(businessId) {
    window.open(
      window.location.origin + `/delegationRegistration/process`,
      `DelegationRegistrationProcessing`,
      `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`,
    );
  }
  get columns() {
    return DelegationRegistrationListModel.columns;
  }
  get tabs() {
    return DelegationRegistrationListModel.tabs;
  }
  onTab(delegationRegistrationStatusType, setFilterModel) {
    if (delegationRegistrationStatusType) {
      pageRouter.replace(`/delegationRegistration/list?type=${delegationRegistrationStatusType}`);
    } else {
      pageRouter.replace(`/delegationRegistration/list`);
    }
  }
  gridData(server) {
    return {
      getRows: async (params) => {
        const jsonRequest = params.request;

        jsonRequest.tab = this.state.activeTab;
        const res = await DelegationRegistrationListService.gridData(jsonRequest);

        params.successCallback(res.rows.data, res.rows.lastRow);
      },
    };
  }
  onDragStopped(e) {
    const sendData = e.columnApi.getColumnState();

    DelegationRegistrationListService.onDragStopped(sendData);
  }
  onChangeData(e) {
    const sendData = {
      type: e.type,
      pinned: e.pinned,
      visible: e.visible,
      colIds: e?.columns?.map((column) => column.colId),
      value: e.value,
      colId: e?.column?.colId,
      rowData: e.data,
    };

    DelegationRegistrationListService.onChangeData(sendData);
  }
  onGridPageSizeChanged(e, gridRef) {
    const value = e.target.value;

    gridRef.current.api.paginationSetPageSize(Number(value));
  }
}

export default new DelegationRegistrationListVm();
