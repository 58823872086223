import LoginService from '@service/LoginService';
import { runInAction, observable, toJS } from 'mobx';
import UserSettingService from '@service/UserSettingService';
import UserSettingModel from '@model/UserSettingModel';
import { _alert, _confirm } from '@model/dialogModel';
import formDataToJson from '@common/module/submit';
import portalModel from '@model/portalModel';
import MarketingTermsModal from './MarketingTermsModal/MarketingTermsModal';
import regExp from '@common/module/regExp';

class UserSettingVm {
  constructor() {
    this.state = observable({
      activeTab: '이메일',
      password: '',
      newPassword: '',
      newPasswordCheck: '',
    });
  }
  get tabList() {
    return ['이메일', '비밀번호', '회원정보'];
  }
  logout() {
    portalModel.title = null;
    portalModel.content = null;
    LoginService.logout();
  }
  changeTab(tab) {
    this.state.activeTab = tab;
  }
  load() {
    UserSettingService.load();
  }
  get userSetData() {
    return UserSettingModel.userSetData;
  }
  async changeEmail(email) {
    await UserSettingService.sendChangeEmail();
    portalModel.title = null;
    portalModel.content = null;
    LoginService.logout();
    await _alert(`${email}로 변경이메일이 발송되었습니다. 이메일 변경 후 다시 로그인해주세요.`);
  }
  setPassword(e) {
    this.state.password = regExp.deleteSpace(e.target.value);
  }
  setNewPassword(e) {
    this.state.newPassword = regExp.deleteSpace(e.target.value);
  }
  setNewPasswordCheck(e) {
    this.state.newPasswordCheck = regExp.deleteSpace(e.target.value);
  }
  isSame() {
    if (this.state.newPassword === this.state.newPasswordCheck) {
      return true;
    } else {
      return false;
    }
  }
  async passwordChangeSubmit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      await UserSettingService.changePassword(param);
      portalModel.content = null;
      LoginService.logout();
      await _alert('비밀번호가 변경되었습니다.');
    }
  }
  changeName(e) {
    runInAction(() => {
      UserSettingModel.userSetData.name = e.target.value;
    });
  }
  async nameChangeSubmit(e) {
    e.preventDefault();
    if (formDataToJson(e.target).error) {
      return;
    } else {
      let param = formDataToJson(e.target).data;
      await UserSettingService.changeName(param);
      await _alert('이름이 변경되었습니다.');
    }
  }
  async changeMarketingAcceptYn(e) {
    UserSettingModel.userSetData.marketingAcceptYn = e.target.checked;
    if (e.target.checked === false) {
      if (await _confirm('할인정보 등 프로모션 정보 수신 동의를 철회 하시겠습니까?')) {
        UserSettingModel.userSetData.marketingAcceptYn = false;
        UserSettingService.changeMarketing(false);
      } else {
        UserSettingModel.userSetData.marketingAcceptYn = true;
      }
    } else {
      UserSettingService.changeMarketing(true);
      await _alert('할인정보 등 프로모션 정보 수신에 동의 완료되었습니다.', '- 이메일 수신 동의 완료');
    }
  }
  async changeMarketingAcceptEmailYn(e) {
    UserSettingModel.userSetData.marketingAcceptEmailYn = e.target.checked;
    UserSettingService.changeMarketingDetail();
    if (e.target.checked === true) {
      await _alert('할인정보 등 프로모션 정보 수신에 동의 완료되었습니다.', '- 이메일 수신 동의 완료');
    }
  }
  marketingTermContentModal() {
    portalModel.title = '할인정보 등 프로모션 정보 수신 동의';
    portalModel.content = <MarketingTermsModal content={UserSettingModel.userSetData.marketingTermContent} />;
  }
}
export default UserSettingVm;
