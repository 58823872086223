import { observable } from 'mobx';

const StaffListModel = observable({
  employees: [],
  setEmployees(data) {
    this.employees = data?.employees
      ? data?.employees.map((item) => {
          return {
            name: item?.name || '',
            title: item?.title || '',
            email: item?.email || '',
            role: item?.role || '',
            teams: item?.teams
              ? item?.teams.map((team) => {
                  return team || '';
                })
              : [],
          };
        })
      : [];
  },
});

export default StaffListModel;
