import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx';
import DelegationRegistrationListModel from '@model/DelegationRegistrationListModel';

class DelegationRegistrationListService {
  async load() {
    const res = await get(`/delegationRegistration/grid/column`);
    DelegationRegistrationListModel.setColumns(res.data.columns);
  }
  async gridData(params) {
    const res = await post('/delegationRegistration/grid', params);
    DelegationRegistrationListModel.setTabs(res.data.tabs);
    return res.data;
  }
  async onDragStopped(params) {
    await put('/delegationRegistration/grid/column/state', params);
  }
  async onChangeData(params) {
    await put('/delegationRegistration/grid/column', params);
  }
}
export default new DelegationRegistrationListService();
