import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import vm from './ValueAddedTaxProcessingVm';
import st from './ValueAddedTaxProcessing.module.scss';
import queryString from 'query-string';

function ValueAddedTaxProcessing(props) {
  let location = useLocation();
  const selectedIndex = queryString.parse(location.search).selectedIndex;

  return useObserver(() => (
    <button type="button" className={st.gridBtn} onClick={() => vm.openWindow(props.data.businessId, selectedIndex)}>
      업무 처리
    </button>
  ));
}
export default ValueAddedTaxProcessing;
